.get-fee-calculator {
  display: flex;
  font-family: "Work Sans", sans-serif;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  margin-top: 35px;
  margin-bottom: 30px;

  background-color: white;
  padding: 32px 16px;
  border-radius: 4px;

  .asset {
    width: fit-content;
    margin: 0 auto;
    flex-shrink: 0;

    img {
      width: 298px;
      height: 232px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    h2 {
      color: #333333;
      font-size: 20px;
      /* line-height: 24px; */
      font-weight: 600;
      text-align: center;
    }

    p {
      color: #666666;
      font-size: 16px;
      line-height: 28px;
      font-weight: 500;
      font-family: "Work Sans", sans-serif;
      text-align: center;
      margin: 0;
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: white;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
      padding: 10px 16px;
      margin-top: 20px;
      background-color: #2b2e8c;
      border-radius: 8px;
    }
  }

  @media (min-width: 768px) {
    & {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 44px 72px;
    }

    .asset {
      margin: 0;
    }

    .asset img {
      width: 286px;
      height: 212px
    }

    .content {
      gap: 16px;
      max-width: 60%;

      h2 {
        font-size: 32px;
        text-align: left;
      }

      p {
        font-size: 18px;
        text-align: left;
      }

      a {
        max-width: 270px;
        margin-top: 16px;
      }
    }
  }
}