/*KYC thankyou page*/
.thankyou-sec h2 {
    font-size: 32px;
    font-family: 'Roboto';
    font-weight: 400;
}

.kycpan-errormsg {
    text-align: center;
    padding: 10px;
    background-color: #fbad1c;
    margin: 10px 0;
    color: #fff;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 700;
    text-transform: uppercase;
}

.title-sec h2 {
    display: inline-block;
    text-transform: uppercase;
    color: #27306B;
    font-size: 25px;
    font-family: 'Roboto';
    font-weight: 700;
    vertical-align: middle;
    margin: 0 0;
}

.pls_not_table {
    border: 1px solid #dddddd;
}

.pls_not_heading {
    font-size: 15px;
    padding: 10px 20px;
    background-color: #f5f6fa;
    margin: 0;
}

.pls_not_content {
    font-size: 11px;
    color: #666;
    padding: 10px 20px;
}

.pls_not_content ol li {
    margin-bottom: 10px;
}