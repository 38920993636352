/*--cashflow style started here --*/
.clear {
  clear: both;
}
/* .container {
  z-index: 12;
} */
.background1 {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: -1;
}
.background2 {
  position: absolute;
  top: 216px;
  left: 0;
  z-index: -1;
}
.background3 {
  position: absolute;
  top: 216px;
  left: 0;
  z-index: -1;
}
.background4 {
  position: absolute;
  top: 216px;
  right: 0;
  z-index: -1;
}

/*--bannersection --*/
.bannerwrapper {
  position: relative;
  background: rgb(44, 85, 128);
  background: linear-gradient(
    180deg,
    rgba(44, 85, 128, 1) 0%,
    rgba(64, 99, 153, 1) 70%,
    rgba(49, 71, 111, 1) 100%
  );
}
.bannertop {
  position: relative;
  clear: both;
  padding-top: 50px;
}
.bannerbg {
  position: absolute;
  left: 0px;
  top: 100%;
}
.bannerinfo {
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 999;
  width: 558px;
}
.bannerinfo h2 {
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #fff;
}
.bannerinfo h2 span {
  color: #f58634;
}
.bannergraph {
  position: relative;
  z-index: 0px;
  margin-top: 130px;
}
.indicators {
  position: relative;
  margin-top: 10px;
  float: right;
}
.indicators ul {
  list-style: none;
}
.indicators ul li {
  display: inline-block;
}
.indicators ul li:first-child {
  margin-right: 10px;
}
.indicators ul li span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  text-align: left;
  content: "";
  display: inline-block;
}
.indicators ul li em {
  font-style: normal;
  color: #ffffff;
  text-align: left;
}
.indicators ul li:first-child span {
  background: #91d8f7;
}
.indicators ul li:last-child span {
  background: #f58634;
}

/*--section1--*/
.introductionwrap {
  position: relative;
  padding-top: 327px;
  padding-bottom: 30px;
}
.introductionwrap .content {
  position: relative;
}
.introductionwrap .graphics {
  position: relative;
  z-index: 999;
}
.introductionwrap .content h3 {
  color: #354264;
  font-size: 29px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.introductionwrap .content p {
  font-size: 14px;
  line-height: 19px;
  color: #6a738a;
  text-align: left;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
}

/*--section 2--*/
.cashplannerwrap {
  position: relative;
  padding: 60px 0;
}
.aboutcashplan {
  position: relative;
}
.aboutlist {
  position: relative;
}
.aboutlist ul {
  list-style: none;
  margin: 0px;
  padding-left: 0px;
}
.aboutlist ul li {
  position: relative;
  width: 100%;
  clear: both;
}
.iconabts {
  position: relative;
  width: 63px;
  height: 63px;
  display: table-cell;
}
.aboutlist ul li p {
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 0px;
}
.contentlist {
  width: calc(100% - 63px);
  position: relative;
  display: table-cell;
  vertical-align: middle;
}
.aboutcashplan h3 {
  color: #354264;
  font-size: 29px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.cashplannerwrap {
  position: relative;
}

/*--section3--*/
.benefitwrap {
  position: relative;
}
.benefitwrap h3 {
  color: #354264;
  font-size: 29px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.benlist {
  position: relative;
  margin: 0px;
  padding: 0px;
  padding-left: 15px;
  list-style-type: disc;
}
.benlist li {
  margin-bottom: 10px;
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
}

/*--section4--*/
.howitworks {
  position: relative;
  padding: 60px 0;
}
.howitworks h3 {
  font-size: 29px;
  line-height: 48px;
  text-align: center;
  color: #354264;
  font-weight: 700;
  font-family: "Roboto";
  text-transform: capitalize;
}
.card-iconwrap {
  position: relative;
}
.cardwrap {
  position: relative;
  height: 150px;
  width: auto;
  padding: 25px;
  border-bottom: 5px solid #323b6d;
  background: #ffffff;
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

/* .cardwrap.card-icon1 .iconwrap{width:50px; height:50px; position:relative; display:block; background:url("../img/Movipack/movipack_feature1.svg")no-repeat center center;  background-size: cover;  margin:0; margin-bottom:10px; text-align:center;} */

.cardwrap:hover {
  background: #323b6d;
  color: #ffffff;
}
.cardwrap p {
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  text-align: left;
  font-weight: 400;
}
.cardwrap:hover p {
  color: #ffffff;
}

/*--section5--*/
.illustrationwrap {
  position: relative;
  padding: 0 0 60px;
  padding-top: 30px;
}
.illustrationwrap h3 {
  color: #354264;
  font-size: 29px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 30px;
  text-transform: capitalize;
  position: relative;
}
.illustrationwrap p {
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  text-align: left;
  font-weight: 400;
}
.why-gsec .sweetspot-sec .col-md-6:first-child {
  border-right: 0;
}
.why-gsec h6 {
  padding: 10px 0;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
}
.why-gsec .tg {
  border-collapse: collapse;
  border-color: #dee2e6;
  border-spacing: 0;
}
.why-gsec .tg td {
  border-color: #dee2e6;
  border-style: solid;
  border-width: 1px;
  color: #333;
  font-size: 14px;
  overflow: hidden;
  padding: 5px;
  word-break: normal;
}
.why-gsec .tg th {
  background-color: #f0f0f0;
  border-color: #dee2e6;
  border-style: solid;
  border-width: 1px;
  color: #333;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 5px;
  word-break: normal;
}
.why-gsec .tg th,
.why-gsec .tg .bg-dark-grey .tg-nrix {
  background: #3d3b3a;
  color: #fff;
}
.why-gsec .tg .tg-nrix {
  text-align: center;
  vertical-align: middle;
}
.why-gsec .tg.riskreturn-table th.tg-nrix:first-child {
  width: 9%;
}
.why-gsec .tg.riskreturn-table th.tg-nrix,
.why-gsec .tg.crisis-table th.tg-nrix {
  width: 13%;
}
.why-gsec .tg.riskreturn-table tbody tr:first-child td:first-child,
.why-gsec .tg.riskreturn-table tbody tr:nth-child(4) td:first-child {
  background-color: #f4f1f9;
}
.why-gsec .tg tbody tr:nth-child(odd) {
  background-color: #f4f1f9;
}
.why-gsec .tg.sipperformance-table tbody tr:nth-child(even) {
  background-color: #f4f1f9;
}
.why-gsec .tg tbody tr:nth-child(even),
.why-gsec .tg.sipperformance-table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.why-gsec .tg.crisis-table th.tg-nrix:first-child {
  width: 13%;
}
.why-gsec .tg.crisis-table th.tg-nrix {
  width: 9%;
}
.why-gsec .tg.threeyear-table th.tg-nrix {
  width: 20%;
}

/* new css from amit */

.background1 { position: absolute; top: 0px; right: 0;z-index: -1;}

.background2 { position: absolute; top: 216px; left: 0;  z-index: -1;}

.background3 {position: absolute; top: 216px; left: 0;z-index: -1;}

.background4 { 
  position: absolute; 
  top: 36px; 
  right: 0; 
  z-index: -1; 
  /* width: 578px; */
}

.howitworks { position: relative; padding: 60px 0;}

.illustrationwrap h3::after {background:none;}

.illustrationwrap h3 span{position:relative;}

.illustrationwrap h3 span {width: 71px; height: 16px; display: block; content: ""; position: absolute; left: 0; bottom: -20px; background: url(/assets/images/AMC/underline.svg) no-repeat;}

/*responsive for movie page */
@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1024px) {
  .introductionwrap {
    padding-top: 200px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .introductionwrap {
    padding-top: 170px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .introductionwrap {
    padding-top: 200px;
  }
  .bannerinfo h2 {
    font-size: 30px;
    line-height: 36px;
    text-align: right;
    color: #fff;
  }
  .introductionwrap .content h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .cashplannerwrap {
    padding: 30px 0;
  }
  .aboutcashplan h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .background1 img {
    max-width: 68%;
    float: right;
  }
  .benefitwrap h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .howitworks {
    padding: 30px 0;
  }
  .howitworks h3 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .illustrationwrap h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .illustrationwrap p {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) {
  .introductionwrap {
    padding-top: 200px;
  }
  .bannerinfo h2 {
    font-size: 30px;
    line-height: 36px;
    text-align: right;
    color: #fff;
  }
  .introductionwrap .content h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .cashplannerwrap {
    padding: 30px 0;
  }
  .aboutcashplan h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .background1 img {
    max-width: 68%;
    float: right;
  }
  .benefitwrap h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .howitworks {
    padding: 30px 0;
  }
  .howitworks h3 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .illustrationwrap h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .illustrationwrap p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .howitworks p {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 599px) {
  .introductionwrap {
    padding-top: 200px;
  }
  .introductionwrap {
    padding-top: 125px;
  }
  .bannergraph {
    margin-top: 20px;
  }
  .bannerinfo h2 {
    font-size: 26px;
    line-height: 36px;
    text-align: right;
    color: #fff;
  }
  .bannerinfo {
    position: relative;
    top: -24px;
    right: 0;
    z-index: 999;
    width: 100%;
  }
  .bannertop {
    position: relative;
    clear: both;
    padding-top: 50px;
  }
  .introductionwrap .content h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .cashplannerwrap {
    padding: 30px 0;
  }
  .aboutcashplan h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .background1 img {
    max-width: 68%;
    float: right;
  }
  .benefitwrap h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .howitworks {
    padding: 30px 0;
  }
  .howitworks h3 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: left;
  }
  .illustrationwrap h3 {
    font-size: 22px;
    line-height: 26px;
  }
  .illustrationwrap p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .howitworks p {
    margin-bottom: 15px;
  }
  .iconabts {
    text-align: center;
    display: block;
    margin: 0 auto 15px;
  }
  .aboutlist ul li {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 479px) {
  .introductionwrap {
    padding-top: 200px;
  }
  .introductionwrap {
    padding-top: 70px;
  }
  .bannerinfo h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .introductionwrap .content h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .cashplannerwrap {
    padding: 30px 0;
  }
  .aboutcashplan h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .background1 img {
    max-width: 68%;
    float: right;
  }
  .benefitwrap h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .howitworks {
    padding: 30px 0;
  }
  .howitworks h3 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    text-align: left;
  }
  .illustrationwrap h3 {
    font-size: 18px;
    line-height: 24px;
  }
  .illustrationwrap p {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .howitworks p {
    margin-bottom: 15px;
  }
  .iconabts {
    text-align: center;
    display: block;
    margin: 0 auto 15px;
  }
  .aboutlist ul li {
    margin-bottom: 15px;
  }
}
