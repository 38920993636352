
.our-strategy-pms-performance-container .pms-performance-options-container{
    /* top: 1px;
    left: 2px; */
    border-radius: 18px;
    width: 50%;
    line-height: 28px;
    font-size: 12px;
    color: #FFFFFF;
    font-family: "Roboto";
    font-weight: 400;
    background: #323b6d;
    text-transform: uppercase;   
    text-align: center; 
}
