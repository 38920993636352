/*moviepack section style here */
/* body {
  background: #fafafa;
} */
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  position: relative;
}
.bannerwrap {
  position: relative;
  background: #e4eafe;
}
.banner {
  position: relative;
  padding-top: 20px;
}
.banner .leftside {
  position: relative;
}
.banner .leftside h1 {
  font-size: 45px;
  line-height: 50px;
  color: #21295a;
  text-align: left;
  font-weight: 900;
  font-family: "Roboto";
  text-transform: capitalize;
  margin-bottom: 20px;
}
.banner .leftside h2 {
  font-size: 30px;
  line-height: 34px;
  color: #21295a;
  text-align: left;
  font-weight: 600;
  font-family: "Roboto";
  text-transform: capitalize;
}
.bottomedge {
  width: 100%;
  content: "";
  height: auto;
  position: absolute;
  bottom: -40px;
  z-index: 999;
}
.bottomedge img {
  width: 100%;
}
.banner p {
  color: #6a738a;
  font-size: 13px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
}
.background1 {
  position: absolute;
  top: 60px;
  left: 0px;
  z-index: 0;
  width: 580px;
}
.background2 {
  position: absolute;
  bottom: 0;
  right: 0px;
  z-index: 0;
  width: 654px;
}
.background3 {
  position: absolute;
  bottom: 0;
  left: 0px;
  z-index: -1;
  width: 654px;
}
.background4 {
  position: absolute;
  top: 36px;
  right: 0px;
  z-index: -1;
  /* width: 578px; */
}

.whymovie {
  position: relative;
  padding-bottom: 40px;
  padding-top: 60px;
}
.whymovie h3 {
  font-size: 29px;
  color: #354264;
  text-align: left;
  font-weight: 700;
  font-family: "Roboto";
  text-transform: capitalize;
}
.whymovie p {
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}

.movieplan {
  position: relative;
  padding: 80px 0;
}
.movieplan h3 {
  font-size: 29px;
  color: #354264;
  text-align: left;
  font-weight: 700;
  font-family: "Roboto";
  text-transform: capitalize;
}
.movieplan h5 {
  font-size: 16px;
  color: #444444;
  text-align: left;
  font-weight: 700;
  font-family: "Roboto";
  text-transform: capitalize;
}
.movieplan p {
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 10px;
}

.graphisc {
  position: relative;
  z-index: 1;
}

.howitworks {
  position: relative;
  padding: 30px 0;
}
.howitworks h3 {
  font-size: 29px;
  line-height: 48px;
  color: #354264;
  font-weight: 700;
  font-family: "Roboto";
  text-transform: capitalize;
}
.card-iconwrap {
  position: relative;
}
.cardwrap {
  position: relative;
  height: 229px;
  width: auto;
  padding: 25px;
  border-bottom: 5px solid #323b6d;
  margin-bottom: 15px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
.cardwrap.card-icon1 .iconwrap {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  background: url("../assets/images/movipack/movipack_feature1.svg") no-repeat
    center center;
  background-size: cover;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.cardwrap.card-icon1:hover .iconwrap {
  background: url("../assets/images/movipack/movipack_feature1hover.svg")
    no-repeat center center;
  background-size: cover;
}
.cardwrap.card-icon2 .iconwrap {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  background: url("../assets/images/movipack/movipack_feature2.svg") no-repeat
    center center;
  background-size: cover;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.cardwrap.card-icon2:hover .iconwrap {
  background: url("../assets/images/movipack/movipack_feature2hover.svg")
    no-repeat center center;
  background-size: cover;
}
.cardwrap.card-icon3 .iconwrap {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  background: url("../assets/images/movipack/movipack_feature3.svg") no-repeat
    center center;
  background-size: cover;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.cardwrap.card-icon3:hover .iconwrap {
  background: url("../assets/images/movipack/movipack_feature3hover.svg")
    no-repeat center center;
  background-size: cover;
}
.cardwrap.card-icon4 .iconwrap {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  background: url("../assets/images/movipack/movipack_feature4.svg") no-repeat
    center center;
  background-size: cover;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.cardwrap.card-icon4:hover .iconwrap {
  background: url("../assets/images/movipack/movipack_feature4hover.svg")
    no-repeat center center;
  background-size: cover;
}
.cardwrap.card-icon5 .iconwrap {
  width: 50px;
  height: 50px;
  position: relative;
  display: block;
  background: url("../assets/images/movipack/movipack_feature5.svg") no-repeat
    center center;
  background-size: cover;
  margin: 0;
  margin-bottom: 10px;
  text-align: center;
}
.cardwrap.card-icon5:hover .iconwrap {
  background: url("../assets/images/movipack/movipack_feature5hover.svg")
    no-repeat center center;
  background-size: cover;
}
.cardwrap:hover {
  background: #323b6d;
  color: #ffffff;
}
.cardwrap p {
  font-size: 14px;
  line-height: 19px;
  color: #444444;
  text-align: left;
  font-weight: 400;
  padding-left: 0px;
}
.cardwrap:hover p {
  color: #ffffff;
}
.item {
  position: relative;
  margin-bottom: 15px;
}
.item h3 {
  color: #fff;
}
.tableinfo {
  position: relative;
  padding: 30px 0;
}
.nav-tabs .nav-link {
  border-color: rgb(224, 224, 224);
  border-width: 1px 1px 0px;
  border-radius: 5px 5px 0px 0px;
  padding: 1rem 1.5rem;
  margin: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: rgba(68, 64, 101, 0.15);
  box-shadow: rgb(0 0 0 / 5%) -9px -4px 8px -5px,
    rgb(0 0 0 / 5%) 8px -4px 8px -5px;
  text-transform: none;
  font-size: 15px;
  color: #666;
  font-family: "Roboto";
  font-weight: 600;
}
.nav-tabs .nav-link.active {
  background: #21295a !important;
  color: #fff;
}
.nav-tabs .nav-link {
  background: #8c90a9;
  color: #fff;
  cursor: pointer;
}
.tableinfo div#nav-tab {
  box-shadow: rgb(0 0 0 / 10%) 0px -15px 15px -16px inset;
  background: #fafafa !important;
}
div#nav-tabContent {
  background: #ffffff;
  padding: 15px;
}
.filters {
  position: relative;
}
.periodwrap {
  position: relative;
}
.filters label.btn {
  padding: 1px 5px;
  margin-right: 10px;
}
.filters label.btn.active {
  background: #000000;
  color: #ffffff;
  border-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.icon_calender {
  width: 15px;
  height: 15px;
  background: url(../assets/images/movipack/calender.svg) no-repeat;
  display: inline-block;
  position: absolute;
  right: 20px;
  bottom: 28px;
}
.txtbox {
  width: 100%;
  height: 20px;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #bfc3ce;
  background: transparent;
  font-size: 14px;
  color: #323b6d;
  font-family: "Roboto";
}
.filters .input-lable {
  color: #adafb6;
  font-size: 10px;
  font-family: "Roboto";
  text-transform: uppercase;
  margin-bottom: 0px;
}
.btn.submit {
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  margin-right: 10px;
  color: #ffffff;
  background: #fb4343;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  padding: 5px;
  line-height: 30px;
}
.btn.excel {
  width: 40px;
  height: 40px;
  font-size: 15px;
  line-height: 20px;
  background: url("../assets/images/movipack/xls.png") no-repeat center center;
  border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  padding: 5px;
  line-height: 30px;
}
.tab-pane td {
  color: rgb(51, 51, 51);
  font-size: 14px;
  word-break: normal;
  overflow: hidden;
  padding: 5px;
  border-color: #dee2e6;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  color: #333;
  overflow: hidden;
}
.tab-pane th {
  color: rgb(255, 255, 255);
  background: #3d3b3a;
  padding: 10px 10px;
}
.tab-pane .tg {
  border-collapse: collapse;
  border-color: #dee2e6;
  border-spacing: 0px;
}
.tab-pane .tg .tg-nrix {
  text-align: center;
  vertical-align: middle;
  border: 1px solid #dee2e6;
}
.tab-pane .tg tbody tr:nth-child(even) {
  background-color: #ffffff;
}
.tab-pane .tg tbody tr:nth-child(odd) {
  background-color: #f4f1f9;
}

.aboutmovi {
  position: relative;
  padding: 30px 0;
}
.aboutmovi h3 {
  font-size: 29px;
  color: #354264;
  text-align: center;
  font-weight: 700;
  font-family: "Roboto";
  line-height: 59px;
}
.abt-feature {
  position: relative;
  padding: 15px;
  min-height: 318px;
  background: #ffffff;
  z-index: 1;
}
.abt-feature p {
  font-size: 12px;
  line-height: 16px;
  color: #6a738a;
  text-align: left;
}
.wrapboxer {
  position: relation;
  padding: 4px 10px;
}
.featurelogo {
  width: 61px;
  height: 61px;
  position: relative;
  margin: 0 auto;
  text-align: center;
  display: block;
  margin-bottom: 15px;
}
.abt-feature.logo1 .featurelogo {
  background: url(../assets/images/movipack/about_icon1.svg) no-repeat center
    center;
}
.abt-feature.logo1:hover .featurelogo {
  background: url(../assets/images/movipack/about_icon1hover.svg) no-repeat
    center center;
}
.abt-feature.logo2 .featurelogo {
  background: url(../assets/images/movipack/about_icon2.svg) no-repeat center
    center;
}
.abt-feature.logo2:hover .featurelogo {
  background: url(../assets/images/movipack/about_icon2hover.svg) no-repeat
    center center;
}
.abt-feature.logo3 .featurelogo {
  background: url(../assets/images/movipack/about_icon3.svg) no-repeat center
    center;
}
.abt-feature.logo3:hover .featurelogo {
  background: url(../assets/images/movipack/about_icon3hover.svg) no-repeat
    center center;
}
.abt-feature.logo4 .featurelogo {
  background: url(../assets/images/movipack/about_icon4.svg) no-repeat center
    center;
}
.abt-feature.logo4:hover .featurelogo {
  background: url(../assets/images/movipack/about_icon4hover.svg) no-repeat
    center center;
}
.abt-feature.logo5 .featurelogo {
  background: url(../assets/images/movipack/about_icon5.svg) no-repeat center
    center;
}
.abt-feature.logo5:hover .featurelogo {
  background: url(../assets/images/movipack/about_icon5hover.svg) no-repeat
    center center;
}
.abt-feature:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px -15px 15px -16px inset;
  transform: scale(0.98);
}
.wrapboxer::before {
  width: 186px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #354264;
  height: 40px;
  z-index: 0;
  content: "";
}
.wrapboxer::after {
  width: 186px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: #354264;
  height: 40px;
  z-index: 0;
  content: "";
}

.prationsection {
  position: relative;
  padding: 50px 0;
}
#pricebx {
  position: relative;
}
.pricebx {
  background: #ffffff;
  padding: 32px;
  min-height: 445px;
}
.pricebx h3 {
  font-size: 29px;
  line-height: 48px;
  color: #354264;
  text-align: left;
  font-weight: 700;
  text-transform: capitalize;
  font-family: "Roboto";
}
.pricebx p {
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;
  font-weight: 400;
}
.pricebx:hover {
  box-shadow: rgb(0 0 0 / 10%) 0px -15px 15px -16px inset;
  transform: scale(0.99);
}
.outerwrapper {
  position: relative;
  padding: 30px;
}

.charlist {
  position: relative;
  margin: 0px;
  padding-left: 10px;
  list-style-type: disc;
  margin-bottom: 15px;
}
.charlist li {
  font-size: 14px;
  line-height: 19px;
  color: #6a738a;
  text-align: left;
  margin-bottom: 5px;
}
.charlist li b {
  display: inline-block;
}

.content {
  position: relative;
}
.wrapcontent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.keytermswrap {
  position: relative;
  padding: 30px 0;
}
.keytermswrap h3 {
  font-size: 29px;
  line-height: 59px;
  color: #354264;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
  font-family: "Roboto";
  text-transform: capitalize;
}
.termswrap {
  position: relative;
  min-height: 238px;
  padding: 25px;
  margin-bottom: 30px;
  background: #ffffff;
  z-index: 999;
  border: 1px solid #fbfbfb;
}
.termswrap h6 {
  color: #6a738a;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  font-family: "Roboto";
  font-weight: 600;
  margin-bottom: 5px;
}
.termswrap p {
  color: #6a738a;
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
}
.keytermswrap .container {
  z-index: 2;
}
.termswrap:hover {
  box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}

.productlabelling {
  position: relative;
  padding: 75px 0;
}
.productlabelling .tg .tg-nrix {
  text-align: left;
  vertical-align: middle;
  padding: 10px 15px;
  border: 1px solid #dee2e6;
}
.productlabelling td {
  border: 1px solid #dee2e6;
  color: #6a738a;
  font-size: 14px;
  overflow: hidden;
  padding: 5px;
  word-break: normal;
}
.productlabelling th {
  color: rgb(255, 255, 255);
  background: rgb(61, 59, 58);
}
.productlabelling .tg {
  border-collapse: collapse;
  border-spacing: 0px;
}
.productlabelling .table-responsive {
  position: relative;
  z-index: 99;
  margin-bottom: 10px;
}
.productlabelling h3 {
  font-size: 29px;
  line-height: 59px;
  color: #354264;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
  position: relative;
  z-index: 99;
  text-transform: capitalize;
}
.productlabelling .background2 {
  top: -110px;
}
.productlabelling p {
  color: #6a738a;
  font-size: 10px;
  line-height: 14px;
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
}

.disclaimer {
  position: relative;
  background: #e4eafe;
  padding: 75px 0;
}
.disclaimer h3 {
  font-size: 29px;
  line-height: 59px;
  color: #354264;
  text-align: center;
  font-weight: 700;
  margin-bottom: 30px;
  font-family: "Roboto";
  text-transform: capitalize;
}
.disclaimer p {
  margin-bottom: 10px;
  color: #6a738a;
}

.whymovie .content {
  z-index: 99;
}
.movieplan .content {
  z-index: 99;
}
.dividendyeild .content {
  z-index: 99;
}

/*--custom buttons for nav --*/
.owl-prev,
.owl-next,
.owl-dot {
  font-family: "fontAwesome";
}
.owl-prev::before {
  content: "\f053";
  margin-right: 10px;
  font-size: 0px;
}
.owl-next::after {
  content: "\f054";
  margin-right: 10px;
  font-size: 0px;
}
.owl-nav {
  z-index: -1;
  position: absolute;
  top: 50%;
  width: 100%;
}
.owl-prev {
  margin-left: -20px;
  left: 0px;
  width: 30px;
  display: inline-block;
  float: left;
}
.owl-next {
  margin-right: -38px;
  right: 0px;
  width: 30px;
  display: inline-block;
  float: right;
}
.slider_nav {
  display: none;
}

/* new css from amit */
.tableinfo div#nav-tab {
  box-shadow: rgb(0 0 0 / 10%) 0px -15px 15px -16px inset;
  background: #fafafa !important;
}

.wrapboxer {
  position: relative !important;
}

.wrapboxer:before {
  top: 4px;
  left: 10px;
}

.wrapboxer:after {
  bottom: 4px;
  right: 10px;
}

.background3 {
  position: absolute;
  bottom: 0;
  left: 0px;
  z-index: -1;
  width: 654px;
}

.productlabelling .background2 {
  top: -110px;
}

/*responsive for movie page */
@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1024px) {
  .bottomedge {
    bottom: -39px;
  }
  .pricebx {
    min-height: 468px;
  }
  .pricebx p {
    font-size: 14px;
  }
  .movieplan {
    padding: 30px 0px;
  }
  .termswrap p {
    font-size: 14px;
  }
  .productlabelling {
    padding: 30px 0px;
  }
  .disclaimer {
    padding: 30px 0px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 992px) {
  .productlabelling {
    padding: 30px 0px;
  }
  .disclaimer {
    padding: 30px 0px;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .banner .leftside h1 {
    font-size: 32px;
    line-height: 40px;
  }
  .whymovie {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .graphisc {
    margin-bottom: 10px;
  }
  .aboutmovi h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 10px;
  }
  .whymovie h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .whymovie p {
    font-size: 13px;
    line-height: 17px;
  }
  .nav-tabs .nav-link {
    padding: 1rem 0.5rem;
  }
  .pricebx h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .pricebx p {
    font-size: 13px;
    line-height: 17px;
  }
  .movieplan h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .movieplan p {
    font-size: 13px;
    line-height: 17px;
  }
  .prationsection {
    padding: 20px 0;
  }
  .movieplan h5 {
    font-size: 13px;
    line-height: 17px;
  }
  .charlist li {
    font-size: 13px;
    line-height: 17px;
  }
  .movieplan {
    padding: 20px 0;
  }
  .keytermswrap h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .termswrap h6 {
    font-size: 13px;
    line-height: 17px;
  }
  .termswrap p {
    font-size: 13px;
    line-height: 17px;
  }
  .bottomedge {
    bottom: -21px;
  }
  .productlabelling {
    padding: 20px 0;
  }
  .disclaimer {
    padding: 20px 0;
  }
  .termswrap {
    min-height: 190px;
  }
}

@media screen and (max-width: 767px) {
  .nav-tabs .nav-link {
    padding: 1rem 1.5rem;
  }
  .nav-tabs a.nav-link {
    box-shadow: none;
  }
  .banner .leftside h1 {
    font-size: 30px;
    line-height: 34px;
  }
  .banner {
    padding-bottom: 30px;
  }
  .whymovie {
    padding-top: 10px;
  }
  .background1 {
    width: 100%;
  }
  .background1 img {
    width: 100%;
  }
  .background2 {
    width: 100%;
  }
  .background2 img {
    width: 100%;
  }
  .movieplan {
    padding: 20px;
  }
  .whymovie h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .movieplan h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .howitworks h3 {
    font-size: 28px;
    line-height: 32px;
  }
  .graphisc {
    margin-bottom: 20px;
  }
  .cardwrap p {
    font-size: 13px;
    line-height: 17px;
  }
  .aboutmovi h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .pricebx h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .keytermswrap h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .termswrap {
    min-height: 140px;
  }
  .productlabelling {
    padding: 20px 0;
  }
  .productlabelling h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .disclaimer {
    padding: 20px 0;
  }
  .disclaimer h3 {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }
  .background1,
  .background2,
  .background3,
  .background4,
  .bottomedge {
    display: none;
  }
}

@media screen and (max-width: 599px) {
  .nav-tabs a.nav-link {
    box-shadow: none;
  }
  .owl-carousel .owl-stage-outer {
    width: 100%;
    margin: 0 auto;
  }
  .owl-nav {
    z-index: 999;
  }
  .owl-prev {
    margin-left: 4px;
  }
  .owl-next {
    margin-right: -15px;
  }
  .abt-feature {
    padding: 15px 25px;
  }
  .background1,
  .background2,
  .background3,
  .background4,
  .bottomedge {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .banner .leftside h1 {
    font-size: 24px;
    line-height: 28px;
  }
  .whymovie h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .movieplan h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .howitworks h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .aboutmovi h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .pricebx h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .keytermswrap h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .productlabelling h3 {
    font-size: 18px;
    line-height: 22px;
  }
  .disclaimer h3 {
    font-size: 18px;
    line-height: 22px;
  }
}
