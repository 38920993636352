.listingtitle {
    padding-top: 0;
}

.pad-60 {
    padding: 15px 60px;
}

.pmsStr {
    margin-bottom: 25px;
    min-height: 242px;
    border-radius: 4px;
    border-bottom: 6px solid transparent;
}

.pmsStr:hover{
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-bottom: 6px solid #005DAC;
    background-image: none;
}

.slideWidth {
    width: 100% !important;
}

.box_shadow {
    box-shadow: 0 0 15px rgb(0 0 0 / 20%);
}

.firstTab {
    /* background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-bottom: 6px solid #FFC107; */
    background-image: linear-gradient(to right, #E5D3E4, #dfe3ef);
}

.secondTab {
    background-image: linear-gradient(to right, #E5D9D3, #DCE1EF);


}

.thirdTab {
    background-image: linear-gradient(to right, #D3E5D5, #DCE0EE);


}

.fourthTab {
    background-image: linear-gradient(to right, #D3E5D5, #DCE0EE);


}

.fifthTab {
    background-image: linear-gradient(to right, #E8DCD0, #ECE9D7);


}

.minheight {
    min-height: 300px;
}

.portfolioHeading {
    font-size: 21px;
    color: #1A294E;
    margin: 0 -5px 20px 0;
    line-height: 28px;
    font-family: 'Roboto';font-weight: 400;
}

.paddingBox {
    padding: 30px 0 0;
}

.graphTabs{
    padding-left: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #FFFFFF;
    border: 1px dashed #e2e2e2;
}
.graphTabs li{
    display: inline;
    width: 50%;
    min-height: 100px;
    padding: 15px 20px;
    cursor: pointer;
    border-right: 1px dashed #e2e2e2;
}
.graphTabs li:last-child{
    border-right: 0px;
}
.activeGraphTab{
    border-bottom: 3px solid var(--primaryBlue);
    box-shadow: 0px 5px 15px 5px #e2e2e2;
}
.graphTabs li h4{
    font-family: 'Roboto';font-weight: 700;
    font-size: 16px;
    color: #1a294e;
}
.graphTabs li p{
    margin-bottom: 0px;
    font-family: 'Roboto';font-weight: 400;
    font-size: 14px;
    color: #6a738a;
    margin-top: 5px;
}
.cagrRed{
    color: #de0d0d !important;
}
.cagrGreen{
    color: #019401 !important;
}
.informationButton {
    font-size: 14px;
    color: var(--primaryBlue);
    text-decoration: none;
    cursor: pointer;
}
.informationButton:hover {
    color: var(--primaryBlue);
    text-decoration: underline;
}
.portfolioInformation {
    font-size: 13px;
    color: #6a738a;
    line-height: 19px;
    margin: 10px 0;
    font-family: 'Roboto';font-weight: 400;
}

@media(min-width:768px) and (max-width:992px) {
    
    .portfolioHeading {
        font-size: 18px;
    }
    
}
@media(min-width:320px) and (max-width:767px) {

    /* PMS Strategies CSS Starts Here */
    .pad-60 {
        padding: 15px;
    }

    .paddingBox {
        padding: 25px 0 20px;
        margin-bottom: 30px;
    }

    .pmsRightIcon {
        float: right;
    }

    .portfolioInformation {
        clear: both;
    }

    .portfolioHeading {
        font-size: 18px;
        margin: 0 -20px 20px 0;
    }

}

@media(min-width:320px) and (max-width:1024px) {
    .graphTabs{
        overflow-x: auto;
    }
    .graphTabs li{
        width: 200px;
        flex: 10 0 auto;
    }
}