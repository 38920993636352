:root {
  --whatsapp-green: #27d045;
  --yellow:#FFCC00
}

.text-whatsapp-green {
  color: var(--whatsapp-green);
  font-weight: 600;
}

.whatsappHeaderPromo .whatsappWrapper h2 {
  margin: 0;
}

.whatsappHeaderPromo {
  box-sizing: content-box;
  height: 60px;
  width: 100%;
  color: white;
  background-color: var(--yellow);
  font-family: Work Sans, "Work Sans", sans-serif;
  /* border-bottom: 1px solid #727272; */
}

.whatsappHeaderPromo * {
  box-sizing: border-box;
}

.whatsappHeaderPromo .whatsappWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 9px;
  margin: auto;
  padding: 0 20px 0 0;
  font-family: Work Sans, "Work Sans", sans-serif;
}

.whatsappHeaderPromo .whatsappWrapper .animated-text {
  height: 60px;
  width: fit-content;
  object-fit: contain;
}

.whatsappHeaderPromo .whatsappWrapper .whatsapp-cta {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 6px;
  width: fit-content;
  flex-shrink: 0;
  color: var(--primaryBlue);
  font-family: "DINCond-Regular";
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  /* border: 2px solid var(--primaryBlue); */
  border-radius: 8px;
  margin: 11px 0;
  background-color: #FFFF;
}

.whatsappHeaderPromo .whatsappWrapper .whatsapp-cta:hover {
  text-decoration: none;
}

.whatsappHeaderPromo .whatsappWrapper .whatsapp-cta img {
  width: 20px;
  height: 20px;
}

.whatsappHeaderPromo .whatsappWrapper .whatsapp-cta span {
  text-wrap: nowrap;
  font-family: Work Sans, sans-serif;
  font-weight: 600;
}

/* Media Queries */
@media (min-width: 368px) and (max-width: 440px) {
  .whatsappHeaderPromo .whatsappWrapper .text-content br {
    display: block;
  }

  .whatsappHeaderPromo .whatsappWrapper .whatsapp-cta span {
    text-wrap: nowrap;
    font-family: Work Sans, sans-serif;
    font-weight: 600;
    font-size: 20px;
  }
}

@media (min-width: 441px) {
  .whatsappHeaderPromo .whatsappWrapper .text-content {
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 768px) {
  .whatsappHeaderPromo .whatsappWrapper {
    padding: 0 20px 0 0;
  }

  .whatsappHeaderPromo .whatsappWrapper .text-content {
    font-size: 18px;
    line-height: 28px;
  }

  .whatsappHeaderPromo .whatsappWrapper .whatsapp-cta {
    font-size: 25px;
    line-height: 28px;
    margin: 9px 0;
  }
}

@media (min-width: 990px) {
  .whatsappHeaderPromo .whatsappWrapper {
    justify-content: center;
    padding: 0 20px 0 0;
  }

  .whatsappHeaderPromo .whatsappWrapper .animated-text {
    height: 54px;
  }
}
