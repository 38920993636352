.headerRevamp {
  * {
    font-family: "Work Sans", sans-serif;
  }

  a {
    /* color: white; */
  }

  /* a:hover {
    color: white;
  } */

  /* Select  */
  .fund-select .fund-select-list .MuiFormControl-root.MuiTextField-root {
    width: fit-content;
  }


  .fund-select .MuiInputBase-root.MuiOutlinedInput-root {
    color: #2B2E8C;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    border: none;
    margin-right: 16px;
  }

  .fund-select .MuiSvgIcon-root.MuiSelect-icon {
    color: #2B2E8C;
    }

  .fund-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    width: fit-content;
    padding: 0 16px 0 0;
  }

  @media (min-width: 990px) {
    .fund-select .MuiInputBase-root.MuiOutlinedInput-root {
      color: #2B2E8C;
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      border: 1px solid #EBECF0;
      border-radius: 8px;
      margin-right: 16px;
      background-color: #F0F0FF;
    }

    .fund-select .MuiSvgIcon-root.MuiSelect-icon {
      color: #2B2E8C;
      scale: 1.1;
      filter: contrast(0.6)
    }

    .fund-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
      width: fit-content;
      padding: 1px 16px 1px 12px;
    }
  }

  /* Menu hover style */
  .menu-popup {
    z-index: 5;
    background-color: white;
    color: #221f1e;
    border-radius: 16px;
  }

  .menu-popup a,
  .menu-popup a:hover {
    color: #221f1e;
  }

  nav li.menu-item:not(:hover) .menu-container {
    display: none !important;
    animation: fadeOut 50ms;
  }

  nav li.menu-item:hover .menu-container {
    display: flex !important;
    animation: fadeIn 50ms;
  }

  nav li.menu-item:hover ~ #header-menu-overlay {
    visibility: visible !important;
  }

  li.menu-item:hover > a,
  li.menu-item:hover > p {
    color: #42526E;
    font-weight: 600;
  }

  li.menu-item:hover > a::after,
  li.menu-item:hover > p::after {
    content: var(--tw-content);
  }

  /* custom css to handle stratergy popup menu */
  @media (min-width: 1560px) {
    .translate-x-custom {
      transform: translateX(-67%);
    }
  }

  /* search box style */
  .custom-search label {
    font-size: 12px;
    color: #b7b7b7;
    top: -12px;
    margin-bottom: 0;
  }

  .custom-search label.Mui-focused {
    top: 4px;
  }

  .custom-search .MuiInputAdornment-root {
    margin-right: 5px;
  }

  .custom-search .MuiAutocomplete-input {
    padding: 2px 4px 2px 2px;
    margin-bottom: 0;
  }

  .custom-search fieldset {
    border-color: #454545 !important;
  }

  .custom-search .MuiInputBase-root {
    color: #b7b7b7;
    padding: 3px 9px 3px 9px !important;
    font-size: 14px;
    border-radius: 60px;
    border-color: #454545;
  }

  .custom-search .MuiAutocomplete-popupIndicator {
    color: #b7b7b7;
    transform: none;
  }

  .custom-search .MuiSvgIcon-fontSizeMedium {
    font-size: 20px;
  }
}

@media (min-width: 1024px) {
  .headerRevamp .container {
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .headerRevamp .container {
    max-width: 1172px;
    padding: 0;
  }
}

@media (min-width: 1680px) {
  .headerRevamp .container {
    max-width: 1486px;
    padding: 0;
  }
}

#mobile-header-menu {
  font-family: Work Sans, sans-serif;

  .fund-select .fund-select-list .MuiFormControl-root.MuiTextField-root {
    font-family: Work Sans, sans-serif;
    width: fit-content;
  }

  .fund-select .MuiInputBase-root.MuiOutlinedInput-root {
    font-family: Work Sans, sans-serif;
    color: #2B2E8C;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    border: none;
    margin-right: 16px;
  }

  .fund-select .MuiSvgIcon-root.MuiSelect-icon {
    color: #2B2E8C;
  }

  .fund-select .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    width: fit-content;
    padding: 0 16px 0 0;
  }

  .MuiButtonBase-root.MuiButton-root {
    font-family: Work Sans, sans-serif;
  }

  fieldset {
    border: none;
  }
}

#mobile-header-search
  .MuiInputBase-root.MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
  font-family: Work Sans, sans-serif;
  height: 44px;
  border-radius: 24px;
}

#mobile-header-search input[type="text"] {
  font-family: Work Sans, sans-serif;
  margin: 0;
}

#mobile-search-popup {
  font-family: Work Sans, sans-serif;
  height: 100vh;
  background-color: white;
  top: 1px !important;
}

#mobile-search-popup .MuiAutocomplete-listbox {
  max-height: 55vh;
}

#mobile-search-popup .MuiPaper-root.MuiPaper-elevation.MuiAutocomplete-paper {
  padding: 0 20px;
  margin-top: 20px;
  box-shadow: unset;
}

#mobile-search-popup .MuiAutocomplete-listbox {
  font-family: Work Sans, sans-serif;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
}

/* Login Button style */
#login-menu .MuiMenu-paper {
  border-radius: 16px;
}

#login-menu .MuiMenu-paper .MuiTypography-body1 {
  font-family: "Work Sans", sans-serif;
  font-size: 14px;
  line-height: 20px;
}

#login-menu .custom-border {
  border-bottom: 1px solid #d7d7d7;
}

#login-menu .MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: unset;
  text-decoration: underline;
}

/* Login Button style */

/* Chat Bot */
#supportchatwidget .circular-widget {
  z-index: 1100;
}
