.col_fullBG {
  width: 100%;
  background: #21295a;
  padding-top: 60px;
  min-height: 300px;
}

.mar_minuCom {
  margin-top: -30px;
  position: relative;
}

.com_head {
  color: #ffffff;
  font-size: 30px;
  font-family: 'Roboto';
    font-weight: 700;
  margin: 0px;
  margin-bottom: 20px;
}

.ul_form {
  list-style: none;
  margin: 0px;
  padding: 0px;
  padding: 7px 12px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
}

.ul_form li {
  width: 29%;
  float: left;
  padding: 0px 5px;
  border: 0px solid red;
  min-height: 40px;
}

.ul_form li:last-child {
  width: 13%;
  text-align: right;
}

.com_btn {
  color: #fff;
  font-size: 14px;
  background: #fb4d4d;
  padding: 9px 27%;
  border-radius: 30px;
  border: 0px;
  font-family: 'Roboto';
    font-weight: 400;
}

.btm_mrg {
  margin-bottom: 0px;
  min-height: auto;
}

.btm_mrg .css-yk16xz-control,
.btm_mrg .css-yk16xz-control:focus {
  border-radius: 4px;
}

.btm_mrg .css-1wa3eu0-placeholder,
.btm_mrg .css-1uccc91-singleValue {
  padding-left: 10px;
}

.com_btn {
  display: block;
  margin: auto;
  text-align: center;
  width: 71%;
  padding: 9px 2px;
}

.table_panel h4 {
  font-size: 15px;
  color: #1a294e;
  font-family: 'Roboto';
    font-weight: 700;
  background: #f3f3f3;
  margin: 0px;
  padding: 18px 15px;
}

.mar_minuCom {
  margin-top: -30px;
  position: relative;
}

.ulCompareFund {
  list-style: none;
  margin: 0px;
  padding: 0px;
  text-align: right;
}

.ulCompareFund li {
  display: inline-block;
  width: 29%;
}

.ulCompareFund li .ulcomparefind {
  background: #fff;
  padding: 20px 15px;
  text-align: left;
  border-radius: 4px;
  border-bottom: 5px solid #7bbec4;
  /* box-shadow:0px 8px 30px 1px #00053A; */
  display: flex;
  flex-wrap: wrap;
  /* align-content: center; */

  flex-direction: column;
  justify-content: flex-start;
  /*min-height: 365px;*/
  min-height: 300px;
}

.ulCompareFund li:nth-child(2) .ulcomparefind {
  border-bottom-color: #ea9a1f;
}

.ulCompareFund li:nth-child(3) .ulcomparefind {
  border-bottom-color: #d8578b;
}

.ulcomparefind h4 {
  color: #1a294e;
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 10px;
}

.ulcomparefind p {
  color: #6a738a;
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 14px;
  margin: 0px;
}

.ulcomparefind button {
  border: 1px solid #fb4d4d;
  box-shadow: none;
  color: #fb4d4d;
  border-radius: 40px;
  padding: 8px 20px;
  background: transparent;
  margin: 20px 0px;
  width: 120px;
  transition: all 0.3s ease;
}

.ulcomparefind button:hover {
  background: #fb4d4d;
  color: #fff;
  border-color: #fb4d4d;
}

.table_panel tbody tr td {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 14px;
  color: #444444;
  padding: 18px 20px;
}

.table_panel tbody tr td p {
  margin-bottom: 5px;
  margin-top: 10px;
}

.table_panel tbody tr td:nth-child(1) {
  font-size: 14px;
  font-family: 'Roboto';
    font-weight: 500;
  color: #1a294e;
}

.table_panel tbody tr td:nth-child(1) p {
  font-size: 12px;
  color: #444444;
  margin-top: 4px;
}

.table_panel .table-bordered > tbody > tr > td {
  border: 1px solid #f4f4f4;
}

.table_panel .table {
  margin-bottom: 0px;
}

.yg_textAlign {
  text-align: right;
}
.yg_compareTableRow td {
  width: 25%;
}
.white_space {
  white-space: break-spaces !important;
}

.colHr h4 {
  margin: 0px;
  font-size: 16px;
  color: #fff;
  font-family: 'Roboto';
    font-weight: 500;
  text-align: center;
  padding: 9px 5px;
  background: transparent;
  border-bottom: 1px solid #878e9f;
}

@media (min-width: 768px) and (max-width: 992px) {
  .ul_form li {
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
  .ul_form li:last-child {
    width: 100%;
  }
  .com_btn {
    width: 100%;
  }
  .com_btn:focus {
    outline: 0px;
  }
  .mar_minuCom {
    margin-top: 15px;
  }
  .ulCompareFund li .ulcomparefind {
    min-height: 475px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .col_fullBG {
    padding-top: 10px;
  }
  .com_head {
    padding: 0px 15px;
  }
  .ul_form li {
    float: none;
    width: 100%;
    margin-bottom: 5px;
  }
  .ul_form li:last-child {
    width: 100%;
  }
  .com_btn {
    width: 100%;
  }
  .com_btn:focus {
    outline: 0px;
  }
  .ulCompareFund li {
    width: 100%;
  }
  .yg_textAlign {
    text-align: left;
  }
  .ulCompareFund li .ulcomparefind {
    min-height: auto;
  }
}
