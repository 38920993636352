.topbannerMargin {
    /* margin-top: 40px; */
}

/* fundDetaildrop */
.fndtal_drop {
    margin-bottom: 7px;
}

.fndtal_drop svg {
    color: #fff;
}

/* .scheme_name_width {
    width: 150%
} */

.fndtal_drop .css-1wa3eu0-placeholder {
    color: #fff;
    font-size: 32px;
    font-family: 'Roboto';font-weight: 700;
}

.fndtal_drop .css-yk16xz-control {
    background: transparent;
    border: 0px;
    color: #fff;
    text-align: left;
    box-shadow: none;
}

.fndtal_drop .css-qc6sy-singleValue{
    color: #fff;
    font-size: 32px;
    font-family: 'Roboto';font-weight: 700;
}
.fndtal_drop .css-1s2u09g-control{
    background: transparent;
    border: 0px;
    color: #fff;
    text-align: left;
    box-shadow: none;
}

.fndtal_drop .css-1okebmr-indicatorSeparator {
    display: none;
}

.fndtal_drop .css-1uccc91-singleValue {
    color: #fff;
    font-size: 32px;
    font-family: 'Roboto';font-weight: 700;
}

.fndtal_drop .css-1pahdxg-control {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    outline: none;
}

.fndtal_drop .css-g1d714-ValueContainer {
    padding-left: 0px;
}

/* mfdetail */
.checkboxWith {
    width: 160px;
    position: relative;
    top: 10px;
}

.ul_meter {
    margin: 0px;
    padding: 0px;
    text-align: left;
    margin-top: 15px;
}

.ul_meter li {
    display: inline-table;
    color: #fff;
    /* padding:0% 2.5%; */
    margin-left: 2%;

}

.ul_meter li:first-child {
    margin-left: 0%;
}

.ul_meter li .over_sec,
.ul_meter li .over_sec h5 {
    margin-bottom: 0px;
    color: #c3c7d0;
    font-family: 'Roboto';font-weight: 400;
    font-size: 16px;
}

.ul_meter li .over_sec h5 img {}

.ul_meter li .over_sec h5 span {
    padding-top: 2px;
}

.ul_meter li p {
    font-size: 18px;
    font-family: 'Roboto';font-weight: 400;
    color: #fff;
}

.ul_meter li p span {
    color: #ffc107;
}


/* overview */
.over_sec {
    margin-bottom: 40px;
    position: relative;
    min-height: 55px;
}

.over_sec h5 {
    margin: 0px;
    color: #1a294e;
    font-size: 16px;
    margin-bottom: 10px;

    font-family: 'Roboto';font-weight: 500;
}

.over_sec h5 span {
    width: 23px;
    height: 23px;
    margin-right: 10px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
}

.over_sec p {
    margin-bottom: 0px;
    color: #707070;
    font-family: 'Roboto';font-weight: 400;
    font-size: 16px;
    margin-left: 25px;
    /* display: -webkit-box;
-webkit-line-clamp: 6;
-webkit-box-orient: vertical;
overflow: hidden; */
}
.yg_readMore{
    opacity: 0;
    position: absolute;
    pointer-events: none;
    }
    .yg_readMore:checked + p {
    -webkit-line-clamp: unset;
    }
    .yg_readMoreLabel{
    float: right;
    color: #707070;
    font-family: 'Roboto';font-weight: 400;
    font-size: 8px;
    cursor: pointer;
    }

.over_sec_img h5 span {
    display: block;
    margin-bottom: 5px;
}

.over_sec_img h5 span img {
    max-height: 100%;
}

.over_sec_img p {
    margin-left: 0px;
}

/* funcManager */
.colManager {
    width: 100%;
    background: #fff;
    padding: 25px;
    margin-bottom: 30px;
    position: relative;
}

.colManager .imgCircle {
    position: absolute;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 100px;

    top: 20px;
    left: -28px;
    overflow: hidden;
}
.imgCircle img{
    max-width: 100%;
}

.nameLeftPadd {
    padding-left: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.colManager h4 {
    margin: 0px;
    color: #1a294e;
    font-family: 'Roboto';font-weight: 700;
    margin-bottom: 6px;
    font-size: 20px;
}

.colManager .desc {
    color: #1a294e;
    font-size: 14px;
    font-family: 'Roboto';font-weight: 400;
}

.colManager .margin_Desc {
    color: #6a738a;
    margin: 20px 0px;
}

.colManager .colmsgSize {
    font-size: 14px;
}

.ul_list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    text-align: left;
    margin-top: 20px;
}

.ul_list li {
    display: inline-table;
    color: #6a738a;
    font-size: 14px;
    font-family: 'Roboto';font-weight: 400;
    border: 1px solid #bfc3ce;
    border-radius: 4px;
    margin-right: 20px;
    padding: 10px 15px;
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 15px;
}

.ul_list li:first-child {
    /* margin-right: 0px; */
}

.rigth_top {
    top: 40%;
}

/* start download */

.colDownload {
    width: 100%;
    height: 110px;
    border-radius: 4px;
    padding: 10px 15px;
    padding-top: 27px;
    padding-left: 20px;
    transition: all 0.3s ease;
    background: #F6F7F8;
    margin-bottom: 15px;
    position: relative;
    border-left: 4px solid transparent;
}

.colDownload:hover {
    background: #fff;
    border-color: #ffc107;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.colDownload span {
    width: 36px;
    height: 45px;
    overflow: hidden;
    display: inline-block;
}


.colDownload p {
    font-size: 18px;
    line-height: 20px;
    color: #424242;
    font-family: 'Roboto';font-weight: 500;
    margin: 0px;
    width: 50%;
    min-height: 45px;
    vertical-align: top;
    display: inline-block;
    margin-left: 15px;

}

.widPDF {
    width: 20px;
    height: 25px;
    display: inline-block;
    position: absolute;
    right: 10%;
    top: 40px;

}


/* end download */

/* product label */

.marBttom {
    margin-bottom: 50px;
    padding: 25px 0px !important;

    position: relative;
}

.height_risk {
    min-height: 400px;
}

.ul_productLable {
    list-style: none;
    margin: 0px;
    padding: 0px;
    color: #6A738A;
    font-family: 'Roboto';font-weight: 400;
    font-size: 16px;
    margin: 40px 0px;

}

.ul_productLable bulletpoint {
    display: block;
}

.ul_productLable li {
    /*display: block;*/
    display: list-item;
    position: relative;
    margin-top: 12px;
    padding-left: 18px;
}

.ul_productLable li span {
    width: 8px;
    display: inline-block;
    height: 8px;
    background: #FFC107;
    border-radius: 100px;
    position: absolute;
    top: 8px;
    left: 0;
}

.absHR {
    position: absolute;
    top: -40px;
    right: 0
}

.ab_bg_hr {
    width: 100%;
    height: 320px;
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    padding: 7% 0px;
    overflow: hidden;
}

.ab_bg_hr img {
    margin: auto;
    display: block;
}
.yg_navPLR{
    padding: 0px 5px 0px 10px;
}
.yg_plHeight{
    min-height: 235px;
}
/*  Share button */
.shareBtn_wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow: hidden;
    cursor: pointer;
    width: 130px;
    height: 40px;
    /* background-color: #EEEEED; */
    border-radius: 20px;
    padding: 8px 15px;
    border: 1px solid #FFFFFF;
    /* will-change: transform; */
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
/* .shareBtn_wrap:hover {
    transition-delay: .4s; 
    -webkit-transform: scale(1.1);
            transform: scale(1.1)
}  */
.shareBtn_wrap span {
    position: absolute;
    z-index: 99;
    width: 130px;
    height: 45px;
    border-radius: 20px;
    font-family: 'Roboto';font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 45px;
    letter-spacing: 2px;
    color: #EEEEED;
    /* background-color: #1F1E1E; */
    -webkit-transition: all 1.2s ease;
    -ms-transition:  all 1.2s ease;
    -moz-transition:  all 1.2s ease;
    transition: all 1.2s ease;
}
.shareBtn_wrap i {
    opacity: 0;
    font-size: 18px;
    color: #FFFFFF;
    will-change: transform;
    -webkit-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -moz-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    -moz-transition:  all 0.1s ease;
    transition: all 0.1s ease;
}
.shareBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 240px;
    height: 64px;
    border-radius: 80px;
}
.shareBtn i:nth-of-type(1) {
            -webkit-transition-delay: 0.2s;
                    transition-delay: 0.2s;
}
.shareBtn i:nth-of-type(2) {
            -webkit-transition-delay: 0.4s;
                    transition-delay: 0.4s;
}
.shareBtn i:nth-of-type(3) {
            -webkit-transition-delay: 0.6s;
                    transition-delay: 0.6s;
}
.shareBtn i:nth-of-type(4) {
            -webkit-transition-delay: 0.8;
                    transition-delay: 0.8s;
}
.shareBtn_wrap:hover span {
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transform: translateX(-280px);
            transform: translateX(-280px)
}
.shareBtn_wrap:hover i {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .shareBtn_wrap span {
        position: relative;
    }
        .shareBtn_wrap:hover span {
        -webkit-transition-delay: 0.1s;
                transition-delay: 0.1s;
        -webkit-transform: translateX(-280px);
                transform: translateX(-280px);
                display: none;
    }
    .share-invest-btngroup.btngroup li{vertical-align: middle;}
    .share-invest-btngroup.btngroup li .invest_btn{margin-bottom: 0;margin-top: 0;}
    .share-invest-btngroup.btngroup li .shareBtn{display: none;}
    .share-invest-btngroup.btngroup li .shareBtn_wrap:hover .shareBtn{display: flex;}
    }
/*  Share button */

/* product label */

@media(min-width:320px) and (max-width:767px) {
    
    .fndtal_drop .css-1uccc91-singleValue {
        font-size: 24px;
    }
    .ul_meter li p{
        font-size: 15px;
        margin-top: 10px;
    }
    .ul_meter li .over_sec{
        font-size: 14px;
    }
    .colManager{
        margin-bottom: 0px;
    }
    .absHR{
        position: static;
    }
    .yg_plHeight{
        min-height: auto;
    }
    .ab_bg_hr{
        height: auto;
    }

}