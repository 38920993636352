.fundDetail {
  width: 100%;
  margin-bottom: 40px;
  background: #fff;
  border-radius: 10px;
  padding: 0px 0px 19px 0px;
}
.fundDetail.box_shadow:hover,
.fundDetail.box_shadow.openinvest {
  box-shadow: 0 0px 20px 0px rgba(78, 78, 78, 0.35);
}
.fundDetail .fund_Name {
  width: 100%;
  /*  height: 120px;
    overflow: hidden;*/
  position: relative;
  padding: 0px 20px;
  background: linear-gradient(45deg, #1a294e, #424c88);
  border-radius: 10px 10px 0 0;
}
/* .fundDetail .fund_Name:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-image: url('../../../assets/images/category/fundcard-bg.svg');
       opacity: 1;
      z-index: 0;
      -webkit-transition: all .3s ease-in;
      -moz-transition: all .3s ease-in;
      -ms-transition: all .3s ease-in;
      -o-transition: all .3s ease-in;
      transition: all .3s ease-in;
  } */

.fundDetail .fund_Name h4 {
  color: #fff;
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 20px;
  margin: 0px;
  padding-top: 35px;
  padding-bottom: 15px;
  display: block;
  position: relative;
}
.listview .fundDetail .fund_Name h4 {
  padding-top: 25px;
}
.fundDetail .fund_Name h4 .fundname-mo {
  display: block;
  font-size: 14px;
}

/* .rupeesBG {
    background: url("../images/rupeesBg.svg") no-repeat center center;
  }
  
  .fofBG {
    background: url("../images/fofBG.svg") no-repeat center center;
  }
  
  .taxBG {
    background: url("../images/taxBG.svg") no-repeat center center;
  }
   */
.icon_same {
  width: 55px;
  height: 57px;
  position: absolute;
  bottom: -2px;
  right: 025px;
  background-position: bottom !important;
  background-size: contain !important;
}

/* .rupeesBG .icon_same {
    background: url("../images/rupeesIcon.svg") no-repeat;
  }
  
  .fofBG .icon_same {
    background: url("../images/fofIcon.svg") no-repeat;
  }
  
  .taxBG .icon_same {
    background: url("../images/taxIcon.svg") no-repeat;
  } */

.fundDetail .fund_type {
  font-family: 'Roboto';
    font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  background: #ffd24d;
  color: #364277;
  padding: 5px 15px;
  margin-bottom: 0px;
  margin-top: -10px;
  display: inline-block;
  width: auto;
  margin-left: 0px;
  border-radius: 30px;
  position: absolute;
  /* z-index: 1; */
  right: 53px;
  top: 20px;
}
.fundDetail .currentinv-amt {
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0px;
  line-height: normal;
  background: #ffffff;
  color: #444;
  padding: 7px 20px;
  margin-bottom: 10px;
  /* margin-top: 15px; */
  display: inline-block;
  width: auto;
  margin-left: -20px;
  border-radius: 0;
  position: relative;
}
.fundDetail .currentinv-amt span {
  font-size: 8px;
  vertical-align: middle;
}

.fundDetail .fund-selection {
  position: relative;
  padding-bottom: 15px;
}
.main-panel .fundDetail .fund-selection .fund-plan.col-5 {
  padding-right: 0;
}
body.sidebar-icon-only
  .main-panel
  .fundDetail
  .fund-selection
  .fund-plan.col-5 {
  padding-right: 15px;
}
.chk-compare {
  position: absolute;
  top: 10px;
  right: 10px;
  /* z-index: 1; */
  /*background: #ff5b5b;
    width: 64px;
    height: 64px;
  */
  box-sizing: border-box;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.chk-compare svg {
  transform: translate3d(0, 0, 0);
}

.compare1 {
  display: none;
}
.compare1:checked + label {
}
.compare1:checked + label svg path {
  opacity: 1;
  fill: #fbbd06;
}

.fundplan-radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fundplan-radio-tile-group .fundplan-input-container {
  position: relative;
  height: 30px;
  width: 30px;
  margin: 0 5px;
}
.fundplan-radio-tile-group .fundplan-input-container:first-child {
  margin-right: 0;
}
.returns-sec .fundplan-radio-tile-group .fundplan-input-container {
  margin: 0 2px;
}
.fundplan-radio-tile-group .fundplan-input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  z-index: 1;
}
.fundplan-radio-tile-group .fundplan-input-container .radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #ededed;
  border-radius: 50%;
  padding: 5px;
  transition: transform 300ms ease;
  background-color: #ededed;
  opacity: 0.5;
  cursor: pointer;
}
.returns-sec .fundplan-radio-tile-group .fundplan-input-container .radio-tile {
  border: 1px solid #ccc;
  padding: 0.5rem;
  opacity: 0.8;
}
.fundplan-radio-tile-group .fundplan-input-container .radio-tile-label {
  text-align: center;
  font-size: 15px;
  margin-bottom: 0;
  font-family: 'Roboto';
    font-weight: 400;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
}
.returns-sec
  .fundplan-radio-tile-group
  .fundplan-input-container
  .radio-tile-label {
  font-size: 11px;
  color: #ccc;
}

.fundplan-radio-tile-group .fundplan-input-container .radio-tile-label small {
  font-size: 50%;
}

.yg_activePlan .radio-tile {
  background-color: #1a294e !important;
  border: 2px solid #fff !important;
  color: white !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35) !important;
  opacity: 1 !important;
}

.yg_activePlan .radio-tile .radio-tile-label {
  color: white !important;
  background-color: #1a294e !important;
  font-family: "robotobold" !important;
}

.yg_activeReturn .radio-tile {
  background-color: #fff !important;
  border: 1px solid #1a294e !important;
  color: #1a294e !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.35) !important;
  opacity: 1 !important;
}
.yg_activeReturn .radio-tile-label {
  color: #1a294e !important;
  background-color: transparent !important;
  font-family: "robotobold" !important;
}

/* .fundplan-radio-tile-group .fundplan-input-container .radio-button:checked + .radio-tile {
    background-color: #1A294E;
    border: 2px solid #fff;
    color: white;
      box-shadow: 0px 3px 5px rgb(0 0 0 / 35%);
          opacity: 1;
  }
  .returns-sec .fundplan-radio-tile-group .fundplan-input-container .radio-button:checked + .radio-tile {
        background-color: #fff;
      border: 1px solid #1A294E;
      color: #1A294E;
      box-shadow: 0px 3px 5px rgb(0 0 0 / 35%);
          opacity: 1;
  }
  .fundplan-radio-tile-group .fundplan-input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: white;
    background-color: #1A294E;    
    font-family: 'Roboto';
    font-weight: 700;
  }
  .returns-sec .fundplan-radio-tile-group .fundplan-input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: #1A294E;
    background-color: transparent;    
    font-family: 'Roboto';
    font-weight: 400;
  } */
.fundDetail .fund-plan label,
.fundDetail .fund-option label {
  text-transform: uppercase;
  padding-right: 8px;
  font-size: 10px;
}
.option-select {
  font-size: 12px;
  line-height: normal;
  color: #fff;
  background-color: transparent;
  display: inline-block;
  border-radius: 0;
  border: transparent;
  border-bottom: 1px solid #bfc3ce;
  width: 66%;
  outline: none;
  padding: 5px 10px 5px 0;
  -webkit-appearance: menulist;
}
.option-select option {
  color: #1a294e;
}
.nav-sec {
  padding: 20px;
}
.nav-innersec {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  -ms-flex-align: center !important;
  align-items: center !important;
  text-align: center;
}
.navdatelbl {
  background-color: #ededed;
  color: #616161;
  text-align: center;
  padding: 10px 10px;
  font-family: 'Roboto';
    font-weight: 700;
  -ms-flex: 0 0 40%;
  flex: 0 0 40%;
  max-width: 40%;
}
.navdatelbl .navdate {
  font-size: 9px;
}
.navdate span {
  padding-left: 2px;
}
.nav_price {
  background-color: #fff;
  text-align: center;
  padding: 5px;
  display: inline-block;
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 20px;
  color: #21295a;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}
.nav_price span.navdown {
  color: #fb4d4d;
  font-size: 12px;
  margin: 0px 0 0 2px;
}
.returns-sec {
  padding: 0 20px 20px;
}
.returns-innersec {
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  text-align: center;
  overflow: hidden;
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.returns-innersec:before {
  content: "";
  background: #fff;
  width: 56px;
  height: 56px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  transform: translateX(-50%);
  position: absolute;
  top: -8px;
  left: 20px;
  z-index: 0;
}
.fundDetail .returns-sec:hover .returns-innersec:before {
  background: #fcb017;
}

.returns-value {
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 20px;
  color: #1a294e;
  line-height: 20px;
  margin-bottom: 0px;
}
.breturns-sec .returns-value {
  color: #485471;
}
.returns-yearslblsec,
.returns-valuelblsec {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.returns-yearslblsec {
  /* -ms-flex-pack: justify !important;
    justify-content: space-between !important; */
}
.main-panel .fundDetail .returns-yearslblsec .col-auto.returnsiconsec,
.main-panel .fundDetail .returns-sec .returns-innersec:before {
  display: none;
}
body.sidebar-icon-only
  .main-panel
  .fundDetail
  .returns-yearslblsec
  .col-auto.returnsiconsec,
body.sidebar-icon-only
  .main-panel
  .fundDetail
  .returns-sec
  .returns-innersec:before {
  display: block;
}
.returns-valuelblsec {
  margin-bottom: 10px;
}
.returns-lbl {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 11px;
  color: #8c94a6;
  line-height: 12px;
  text-transform: uppercase;
}
.returnslbl {
  color: #616161;
  text-align: left;
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 14px;
  display: flex;
  text-transform: uppercase;
  padding: 10px 7px 13px;
  flex: 1 1 auto;
  justify-content: space-between;
}
.returnslbl > label {
  line-height: 16px;
  padding-right: 10px;
}
.returnslbl .returnsdate {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 9px;
  display: block;
  text-transform: none;
}
.returnicon {
  padding-left: 10px;
  padding-top: 10px;
}
.otherpadd {
  padding: 0px 20px;
}

.fund_desc {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.fund_desc > li {
  width: 33.33333%;
  float: left;
  padding: 0px 8px;
  border-left: 1px solid #d1d3d4;
  text-align: center;
}

.fund_desc > li:first-child {
  border: 0px;
}

.amout_text {
  color: #1a294e;
  font-size: 10px;
  font-family: 'Roboto';
    font-weight: 400;
  margin: 0px;
  position: relative;
}
.fund_desc_icon {
  margin-bottom: 5px;
}
.amout_desc {
  color: #999999;
  font-size: 10px;
  font-family: 'Roboto';
    font-weight: 400;
  text-transform: uppercase;
}
.addtocart-btn-sec {
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
.fundDetail .addtocart-btn-sec .col-6:first-child {
  padding-right: 10px;
}
.fundDetail .addtocart-btn-sec .col-6:last-child {
  padding-left: 10px;
}
.btn-fundknowmore {
  background-color: #fff;
  color: #ababab;
  border: 1px solid #e8e8e8;
  font-size: 14px;
  font-family: 'Roboto';
    font-weight: 700;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  /* border: 0; */
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 10px 0 10px;
  width: 100%;
  border-radius: 0.25rem;
  margin: 0 auto;
}
.btn-fundknowmore:hover {
  background-color: #eee;
  border: 1px solid #eee;
  color: #444;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  text-decoration: none;
}
.btn-investnow {
  background-color: #fff;
  color: #fb4d4d;
  border: 1px solid #fb4d4d;
  font-size: 13px;
  font-family: 'Roboto';
    font-weight: 700;
  box-sizing: border-box;
  white-space: nowrap;
  text-decoration: none;
  text-transform: uppercase;
  /* border: 0; */
  outline: 0;
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 10px 0 10px;
  width: 100%;
  border-radius: 0.25rem;
  margin: 0 auto;
}
.btn-investnow:hover {
  background-color: #fb4d4d;
  color: #fff;
  border: 1px solid #fb4d4d;
  text-decoration: none;
  -webkit-box-shadow: 0 5px 10px 0 rgba(238, 52, 57, 0.4);
  box-shadow: 0 5px 10px 0 rgba(238, 52, 57, 0.4);
}

.invest_btn {
  background: #fb4d4d;
  font-size: 16px;
  border-radius: 20px;
  border: 0px;
  color: #fff;
  font-family: 'Roboto';
    font-weight: 400;
  padding: 8px 15px;
  width: 80%;
  max-width: 130px;
  width: 100%;
  outline: none;
}

.btngroup {
  display: block;
  list-style: none;
  text-align: right;
  margin: 0px;
  padding: 0px;
}

.btngroup li {
  width: 130px;
  margin-left: 10px;
}

.btngroup li:first-child {
  margin-left: 0px;
}

.whitebtn {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
/* small black tooltip */
.addtocomparetxt .infoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 6px 10px;
  width: auto;
  position: absolute;
  white-space: nowrap;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  left: -40px;
  top: -45px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
}

.addtocomparetxt .infoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-top-color: #333;
  left: 45%;
  bottom: -12px;
  top: auto;
}

.addtocomparetxt .infoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  bottom: -40px;
  top: auto;
  left: 0;
}

.help-tip.addtocomparetxt {
  display: inline-block;
  margin-left: 0;
  vertical-align: middle;
  cursor: pointer;
}

.help-tip.addtocomparetxt img.info-icon {
  width: 11px;
  vertical-align: baseline;
}

.help-tip.addtocomparetxt:hover .infoHoverText {
  display: block;
  transform-origin: 100% 0;
  -webkit-animation: fadeIn 0.3s ease-in-out;
  animation: fadeIn 0.3s ease-in-out;
}

/* tool tip  */
.tooltip-item::after {
  content: "";
  position: absolute;
  width: 260px;
  height: 20px;
  bottom: 100%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltipn:hover ~ .tooltip-item::after {
  pointer-events: auto;
}

.tooltip-content {
  position: absolute;
  z-index: 999;
  width: 215px;
  left: 30px;
  top: auto;
  bottom: -143px;
  text-align: left;
  font-size: 12px;
  line-height: normal;
  box-shadow: 0px 10px 25px rgba(48, 54, 61, 0.35);
  background: #fff;
  opacity: 0;
  cursor: default;
  pointer-events: none;
  padding: 15px;
  border-radius: 0.25rem;
}
.tooltip-effect-2 ~ .tooltip-content {
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.tooltipn:hover ~ .tooltip-content {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
  transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0);
}
/*Open form css*/
.fundsec-fundinfo {
  position: relative;
}
.quickinvest-form {
  transform: translate3d(-100%, 0, 0);
  -webkit-clip-path: circle(0% at 220px 88%);
  clip-path: circle(0% at 220px 88%);
  transition: -webkit-clip-path 5s ease;
  display: none;
  width: 100%;
  height: 100%;
  /*
      position: absolute;
      left: 0;
      top: 0;
  */
  z-index: 1;
}
.quickinvest-form.active {
  transform: translate3d(0, 0, 0);
  -webkit-clip-path: circle(270% at 220px 88%);
  clip-path: circle(270% at 220px 88%);
  transition: -webkit-clip-path 5s ease;
  padding: 10px 0 0;
  display: block;
}

.trans-type-sec {
  display: none;
}
.postloginfund-card.openinvest .trans-type-sec {
  display: block;
}
.fundDetail.openinvest {
  padding: 0;
}
/* Arrow */

.tooltip-content::after {
  content: "";
  top: 63%;
  left: -20px;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: transparent;
  border-right-color: #fff;
  border-width: 10px;
}

/* Tooltip content*/
.tooltip-content img {
  margin: 0 auto;
  display: block;
  padding-bottom: 10px;
}

.tooltip-text {
  font-size: 12px;
  line-height: normal;
  font-family: 'Roboto';
    font-weight: 400;
  display: block;
  color: #616161;
}
.tooltip-text > p {
  font-family: 'Roboto';
    font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  text-align: center;
}
.tooltip-text > small {
  padding-bottom: 10px;
  font-size: 10px;
  line-height: normal;
  color: #999;
}
.tooltip-text ul {
  list-style: disc;
  padding-left: 15px;
  margin-bottom: 10px;
}

/* list view */

.listview > .col-md-4 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.listview .fundDetail,
.listview .fundDetail .fundsec-fundinfo,
.listview .fundDetail .fundsec-container,
.listview .fundDetail .fundsec-container .fundsec-innercontainer {
  display: flex;
  padding: 0;
}
.listview .fundDetail .fundsec-container .fundsec-innercontainer {
  flex: 1 100%;
}

/*.listview .fundDetail .fundsec-container .fund-plan{flex-wrap: wrap;}*/
/*.listview .fundDetail .fundsec-container .fund-plan .option-select{width: 100%;}*/
.listview .fundDetail .fund_type {
  position: absolute;
  top: 18px;
  right: 50px;
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 30px;
}
.listview .fundDetail .fund_Name {
  -ms-flex: 0 0 38%;
  flex: 0 0 38%;
  max-width: 38%;
  border-radius: 10px 0 0 10px;
}
.listview .fundDetail .fund-selection {
  position: relative;
  margin-bottom: 0;
  padding-top: 20px;
}
.listview .fundDetail .nav-sec {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
}
.listview .fundDetail .nav-sec .nav-innersec {
  display: block;
  min-height: 100%;
}
.listview .fundDetail .nav-sec .nav-innersec .navdatelbl {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  padding: 10px 20px;
  min-height: 100%;
  display: block;
}
.listview .fundDetail .nav-sec .nav-innersec .nav_price {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  display: block;
  line-height: 18px;
  padding: 10px;
  width: 100%;
  align-self: center;
  min-height: 100%;
}
.listview .fundDetail .nav-returns-container {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
  display: flex;
  -ms-flex: 1 100%;
  flex: 1 100%;
  max-width: 100%;
}

.listview .returnslbl > label {
  padding-left: 10px;
}
.listview .fundDetail .returns-sec {
  padding: 0 0 20px;
}
.listview .fundDetail .nav-returns-container .fund_desc_icon {
  display: none;
}
.listview .fundDetail .nav_price span.navdown {
  display: block;
}
.listview .addtocart-btn-sec {
  -ms-flex: 0 0 15%;
  flex: 0 0 15%;
  max-width: 15%;
}
.listview .addtocart-btn-sec > .row {
  height: 100%;
}
.listview .addtocart-btn-sec .col-6 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.listview .fund_desc {
  margin-bottom: 20px;
  position: relative;
  /* z-index: 0; */
}
.listview .returns-innersec {
  display: flex;
  padding-left: 0px;
}
.listview .returns-yearslblsec {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  display: flex;
  margin: 0;
  margin-right: auto !important;
}
.listview .returns-valuelblsec {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  display: flex;
  margin: 0;
}
.listview .returns-valuelblsec .col {
  -ms-flex-align: center !important;
  align-items: center !important;
  flex-basis: auto;
  padding-left: 0px;
}
.listview .returns-valuelblsec .col.breturns-sec {
  display: none;
}
.listview .fundDetail .addtocart-btn-sec .col-6:first-child {
  padding-right: 15px;
  /* padding-bottom: 10px; */
}
.listview .fundDetail .addtocart-btn-sec .col-6:last-child {
  padding-left: 15px;
  /* padding-top: 10px; */
}

.listview .quickinvest-form.active {
  display: flex;
}
.listview .openinvest .trans-type-sec ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 0px; */
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: nowrap;
  height: 100%;
}
.listview .siptoday-chk {
  /* right: 0; */
}

.listview .tabs .content .quickform-sec {
  min-height: 210px;
  max-height: 220px;
  padding: 10px 20px;
}
.listview .tabs .content .quickform-sec .sipinputs-sec {
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
}
.listview .tabs .content .quickform-sec .sipamt-sec {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}
.listview .fundDetail.openinvest .fund_Name {
  -ms-flex: 0 0 26%;
  flex: 0 0 26%;
  max-width: 26%;
}
.listview .fundDetail.openinvest .quickform-sec .minamt-text {
  display: block;
  width: 100%;
}
.listview .trans-type-sec {
  -ms-flex: 0 0 22%;
  flex: 0 0 22%;
  max-width: 22%;
}
.listview .fundsec-fundinfo {
  -ms-flex: 0 0 62%;
  flex: 0 0 62%;
  max-width: 62%;
}
.listview .tabs .content .quickform-sec .amtinput-sec .input-lable {
  display: block;
}
.listview .fundDetail .fundsec-container {
  -ms-flex: 0 0 85%;
  flex: 0 0 85%;
  max-width: 85%;
}
.listview .fundDetail.openinvest .fundsec-container {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.listview .fundDetail.openinvest .fundsec-fundinfo {
  -ms-flex: 0 0 74.1%;
  flex: 0 0 74.1%;
  max-width: 74.1%;
}
.listview .trans-type-sec ul li:first-child,
.listview .trans-type-sec ul li:last-child {
  -ms-flex: 0 0 auto;
  flex: 0 auto;
  max-width: 100%;
  height: 100%;
}
.listview .trans-type-sec ul li:first-child a {
  border-radius: 0 10px 0 0;
}
.listview .trans-type-sec ul li:last-child a {
  border-radius: 0 0 10px 0;
}
.listview .tabs ul li label,
.listview .trans-type-sec ul li a {
  display: flex;
  height: 100%;
  padding: 15px 10px;
}
.listview .trans-type-sec ul li a span {
  margin: auto;
}
.listview .trans-type-sec ul li a.addtocart-btn {
  display: block;
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: none; */
}
.listview .trans-type-sec ul li a.addtocart-btn span {
  display: inline-block;
  font-size: 7px;
  margin-top: auto;
  margin-left: 5px;
}
.listview .trans-type-sec ul li:nth-child(2) {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  position: absolute;
  right: -30px;
  border-radius: 5px;
}
.trans-type-sec ul li a.addtocart-btn .addtocart-img {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  0% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    -ms-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }
  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    -ms-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    -ms-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    -ms-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    -ms-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }
  100% {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.listview .tabs .content .quickform-sec .amt-sec {
  padding: 80px 0px 00px;
  text-align: center;
}
.listview .tabs .content {
  display: flex;
}
.listview .quickinvest-form.active {
  padding: 0;
}
.listview .fundDetail.openinvest .fund-plan {
  flex-direction: column;
}
.listview .fundDetail.openinvest .option-select {
  width: 100%;
}
.listview .note-sec {
  margin-bottom: 5px;
}
.note-sec p img {
  display: inline-block !important;
  margin-left: 5px;
}
.listview .circle {
  right: 152px;
  top: -15px;
}
.sidebar-icon-only .listview .circle {
  right: 183px;
  top: -15px;
}
.sipinputs-sec input.form-control.input-number.text-center {
  height: 34px;
  font-size: 14px;
  border-radius: 0;
}
input.form-control:disabled {
  background-color: #ffffff;
}
.sipenddatebox {
  font-size: 11px;
  color: #3a86ff;
  text-align: right;
}
.listview .returns-lbl {
  font-size: 10px;
}
/* Fund added to cart card design */
.fundaddedtocart.fundDetail.openinvest {
  border: 2px solid #55a602;
}
.fundaddedtocart.fundDetail.openinvest {
  border: 2px solid #55a602;
}
.fundaddedtocart.fundDetail.openinvest a.transtype-btn.active {
  background-color: #55a602;
}
/* Tooltip */
.tooltipn {
  display: inline;
  position: absolute;
  /* right: -7px; */
  z-index: 11;
  top: 0;
  padding-left: 1px;
}
/* end list view */

/* filter start */

.cd-tab-filter-wrapper {
  /* background-color: #ffffff; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08); */
  z-index: 1;
  margin-bottom: 40px;
  padding: 0px 15px;
}
.main-panel .cd-tab-filter-wrapper {
  background-color: transparent;
}
.cd-tab-filter-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.cd-tab-filter {
  /* tabbed navigation style on mobile - dropdown */
  position: relative;
  height: 50px;
  width: 140px;
  margin: 0 auto;
  z-index: 1;
}
.cd-tab-filter::after {
  /* small arrow icon */
  content: "";
  position: absolute;
  right: 14px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  width: 16px;
  height: 16px;
  /* background: url("../img/cd-icon-arrow.svg") no-repeat center center; */
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: none;
}
.cd-tab-filter ul {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  box-shadow: inset 0 -2px 0 #41307c;
}
/* .cd-tab-filter li {
    display: none;
  } */

/* .cd-tab-filter li:first-child {
    
    display: block;
  } */
.cd-tab-filter a {
  display: block;
  /* set same size of the .cd-tab-filter */
  height: 50px;
  width: 140px;
  line-height: 50px;
  cursor: pointer;
  padding-left: 14px;
}
.cd-tab-filter a.selected {
  background: #41307c;
  color: #ffffff;
}
.cd-tab-filter.is-open::after {
  /* small arrow rotation */
  -webkit-transform: translateY(-50%) rotate(-180deg);
  -moz-transform: translateY(-50%) rotate(-180deg);
  -ms-transform: translateY(-50%) rotate(-180deg);
  -o-transform: translateY(-50%) rotate(-180deg);
  transform: translateY(-50%) rotate(-180deg);
}
.cd-tab-filter.is-open ul {
  box-shadow: inset 0 -2px 0 #41307c, 0 2px 10px rgba(0, 0, 0, 0.2);
}
.cd-tab-filter.is-open ul li {
  display: block;
}
.cd-tab-filter.is-open .placeholder a {
  /* reduces the opacity of the placeholder on mobile when the menu is open */
  opacity: 0.4;
}
@media only screen and (min-width: 768px) {
  .cd-tab-filter {
    /* tabbed navigation style on medium devices */
    width: auto;
    cursor: auto;
  }
  .cd-tab-filter::after {
    /* hide the arrow */
    display: none;
  }
  .cd-tab-filter ul {
    background: transparent;
    position: static;
    box-shadow: none;
    text-align: left;
    padding-left: 0;
  }
  .cd-tab-filter li {
    display: inline-block;
  }
  .cd-tab-filter li.placeholder {
    display: none !important;
  }
  .cd-tab-filter a {
    display: block;
    padding: 0 20px;
    width: auto;
    color: #9a9a9a;
    font-weight: 400;
    font-size: 16px;
  }
  .no-touch .cd-tab-filter a:hover {
    color: #1a294e;
    text-decoration: none;
  }
  .cd-tab-filter a.selected {
    background: transparent;
    color: #1a294e;
    font-family: 'Roboto';
    font-weight: 700;
    /* create border bottom using box-shadow property */
    box-shadow: inset 0 -2px 0 #ffc107;
  }
  .cd-tab-filter.is-open ul li {
    display: inline-block;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .returnslbl {
    padding: 10px 7px 13px;
    flex: 1 0 auto;
  }
  .returns-innersec {
    padding-right: 7px;
  }
  .fundplan-radio-tile-group .fundplan-input-container {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-tab-filter {
    /* tabbed navigation on big devices */
    width: 100%;
    float: right;
    margin: 0;
    -webkit-transition: width 0.3s;
    -moz-transition: width 0.3s;
    transition: width 0.3s;
  }
  .cd-tab-filter.filter-is-visible {
    /* reduce width when filter is visible */
    width: 80%;
  }
}

.search-box-sec {
  /* position: absolute;
  right: 135px;
  top: 0px; */
  position: relative;
}
.search-box-innersec {
  float: right;
  /* height: 50px; */
  position: relative;
  width: 100%;
}
.search-box-innersec input {
  border-radius: 0.25rem;
  transition: all 0.6s ease-in-out 0.3s;
  width: 200px;
  border: 1px solid #bfc3ce;
  font-size: 12px;
  height: 40px;
  padding: 4px 8px;
  position: relative;
}
.search-box-innersec .rbt-input-multi.form-control{
  min-height: 40px;
  width: 100%;
  padding: 5px 40px 0px 10px;
}
.search-box-innersec .rbt-input-multi .rbt-input-main{
  margin-top: -11px;
}
.search-box-innersec .rbt-input-multi .rbt-input-wrapper{
  margin-bottom: 0px;
  margin-top: 0px;
}
.search-box-innersec input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #9b9fb5;
  font-size: 14px;
}
.search-box-innersec input::-moz-placeholder {
  /* Firefox 19+ */
  color: #9b9fb5;
  font-size: 14px;
}
.search-box-innersec input:-ms-input-placeholder {
  /* IE 10+ */
  color: #9b9fb5;
  font-size: 14px;
}
.search-box-innersec input:-moz-placeholder {
  /* Firefox 18- */
  color: #9b9fb5;
  font-size: 14px;
}
.search-box-innersec button.btn-search {
  border: none;
  height: 30px;
  font-size: 12px;
  padding: 4px;
  position: absolute;
  width: 30px;
  transition: all 0.6s ease-in-out;
  text-align: center;
}
.search-box-innersec input:focus {
  transition-delay: 0;
  width: 200px;
  outline: 0;
}
.search-box-innersec button.btn-search {
  background: #fff;
  border: 1px solid #bfc3ce;
  border-radius: 0.25rem;
  height: 40px;
  right: 0px;
  top: 0px;
  transition-delay: 0.3s;
  width: 40px;
}
.search-box-innersec input:focus ~ button.btn-search {
  background: #1a294e;
  color: #fff;
  right: 0;
  transition-delay: 0;
}

.list-grid-sec {
  position: absolute;
  right: 0;
  top: 0;
}
.list-grid-sec .input-container:first-child {
  margin: 0 5px 0 0;
}
.input-container {
  position: relative;
  display: inline-block;
  padding: 5px;
  width: auto;
  margin: 0 auto;
}

.input-container .radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
  font-family: 'Roboto';
    font-weight: 400;
}
.input-container .radio-tile {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #cccccc;
  padding: 5px;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  border-radius: 3px;
}
.radio-inputsec .input-container .radio-tile {
  padding: 5px 10px;
  border-radius: 3px;
}
.input-container .radio-tile-label {
  text-align: center;
  font-size: 12px;
  color: #cccccc;
  margin-bottom: 0;
  font-family: 'Roboto';
    font-weight: 400;
  font-weight: 400;
  line-height: normal;
}
.radio-inputsec .input-container .radio-tile-label {
  font-size: 13px;
}
.input-container .radio-tile-label .rupeesign {
  color: #cccccc;
  padding-left: 2px;
}
.input-container .radio-button:checked + .radio-tile {
  background-color: #fff;
  border: 1px solid #21295a;
  color: white;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
.radio-inputsec .input-container .radio-button:checked + .radio-tile {
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
}
.input-container .radio-button:checked + .radio-tile .radio-tile-label {
  color: #21295a;
  border-color: #21295a;
  background-color: #fff;
}
.list-grid-sec {
  position: absolute;
  right: 0;
  top: 0;
}
.list-grid-sec .input-container {
  padding: 0;
}
.list-grid-sec .input-container:first-child {
  margin: 0 5px 0 0;
}
.list-grid-sec .input-container .radio-tile {
  height: 40px;
  width: 40px;
}
.list-grid-sec .input-container .radio-button {
  z-index: 1;
}
.list-grid-sec .input-container .radio-tile img {
  filter: grayscale(1);
  opacity: 0.5;
}
.list-grid-sec .input-container .radio-button:checked + .radio-tile img {
  filter: grayscale(0);
  opacity: 1;
}
/* -------------------------------- 
  
  xfilter 
  
  -------------------------------- */

/* filter end */

/* postlogin */

.tabs {
  position: relative;
  width: 100%;
}
.tabs input[name="tab-control"] {
  display: none;
}

.tabs .content quickform-sec h2,
.tabs ul li label,
.trans-type-sec ul li label {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 15px;
  color: #428bff;
  text-transform: uppercase;
}
.tabs ul,
.trans-type-sec ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tabs ul li,
.trans-type-sec ul li {
  box-sizing: border-box;
  /*
  flex: 1;
  width: 50%;
  */
  padding: 0 0px;
  text-align: center;
}
.trans-type-sec ul li:first-child,
.trans-type-sec ul li:last-child {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.trans-type-sec ul li:nth-child(2) {
  -webkit-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  /* padding: 5px;
    background-color: #fff; */
}
.tabs ul li label,
.trans-type-sec ul li a {
  transition: all 0.3s ease-in-out;
  color: #999999;
  background-color: #ededed;
  padding: 15px 10px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.trans-type-sec ul li a.active {
  background-color: #55a602;
  color: #fff;
}
.trans-type-sec ul li a:hover,
.trans-type-sec ul li a:focus,
.trans-type-sec ul li a:active {
  text-decoration: none;
}
.trans-type-sec ul li:first-child a {
  border-radius: 0 0 0 5px;
}
.trans-type-sec ul li:last-child a {
  border-radius: 0 0 5px 0;
}
.trans-type-sec ul li a.addtocart-btn {
  background-color: #fb4d4d;
  padding: 10px 0;
  border-radius: 0.25rem;
  /* box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25); */
  -moz-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  color: #fff;
  text-align: center;
  position: relative;
}

.trans-type-sec ul li a.addtocart-btn .default-txt {
  position: relative;
  opacity: var(0, 1);
  -webkit-transform: scale(var(1, 1));
  transform: scale(var(1, 1));
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition-delay: var(0, 0.3s);
}
.listview .trans-type-sec ul li a.addtocart-btn .default-txt {
  padding-left: 0px;
  display: block;
  margin-left: 0;
  margin-top: 8px;
}

.trans-type-sec ul li a.addtocart-btn .success-txt {
  opacity: 0;
  -webkit-transform: translate(-50%, 12px);
  transform: translate(-50%, 12px);
  position: absolute;
  top: 19px;
  left: 50%;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  transition-delay: var(0, 0s);
}
.trans-type-sec ul li a.addtocart-btn .addedcart-img {
  position: absolute;
  left: 0;
  top: 10px;
  right: 0;
  bottom: 0;
  z-index: 1;
  -webkit-transform: translate(-50px, 0px);
  transform: translate(-50px, 0px);
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn .addtocart-img {
  -webkit-animation: cart 1s forwards;
  animation: cart 1s forwards;
}
.listview .trans-type-sec ul li a.addtocart-btn.addedtocart-btn .addedcart-img {
  -webkit-animation: addedcart 1s forwards;
  animation: addedcart 1s forwards;
  animation-delay: 0.8s;
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn .addedcart-img {
  -webkit-animation: addedcart 1s forwards;
  animation: addedcart 1s forwards;
  animation-delay: 0.8s;
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn .addedcart-img {
  margin-left: 11%;
}
.sidebar-icon-only
  .trans-type-sec
  ul
  li
  a.addtocart-btn.addedtocart-btn
  .addedcart-img {
  margin-left: 20%;
}
.listview .trans-type-sec ul li a.addtocart-btn.addedtocart-btn .addedcart-img {
  margin-left: -3px;
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn {
  background-color: #55a602;
  z-index: 11;
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn .default-txt {
  opacity: 0;
  transform: scale(0.8);
  transition-delay: 0s;
}
.listview .trans-type-sec ul li a.addtocart-btn.addedtocart-btn .success-txt {
  opacity: 1;
  transform: translate(-58%, 0px);
  transition-delay: 0.8s;
  top: 42px;
}
.trans-type-sec ul li a.addtocart-btn.addedtocart-btn .success-txt {
  opacity: 1;
  transform: translate(-50%, 17px);
  transition-delay: 0.8s;
}
@-webkit-keyframes cart {
  41%,
  49%,
  57%,
  60% {
    -webkit-transform: translateX(50px) rotate(0deg);

    transform: translateX(50px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateX(180px) rotate(-16deg);

    transform: translateX(180px) rotate(-16deg);
  }
}

@keyframes cart {
  41%,
  49%,
  57%,
  60% {
    -webkit-transform: translateX(50px) rotate(0deg);

    transform: translateX(50px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateX(180px) rotate(-16deg);

    transform: translateX(180px) rotate(-16deg);
  }
}

/*
  @-webkit-keyframes addedcart {
  
  41%,
  
    49%,
  
    57%,
  
    60% {
  
    -webkit-transform: translateX(72px) rotate(0deg);
  
            transform: translateX(72px) rotate(0deg);
  
  }
  
  40%,
  
    47%,
  
    54% {
  
    -webkit-transform: translateX(72px) rotate(0deg) translateY(1px);
  
            transform: translateX(72px) rotate(0deg) translateY(1px);
  
  }
  
  100% {
  
    -webkit-transform: translateX(180px) rotate(-16deg);
  
            transform: translateX(180px) rotate(-16deg);
  
  }
  
  }
  */

@keyframes addedcart {
  41%,
  49%,
  57%,
  60% {
    -webkit-transform: translateX(20px) rotate(0deg);

    transform: translateX(20px) rotate(0deg);
  }

  40%,
  47%,
  54% {
    -webkit-transform: translateX(20px) rotate(0deg) translateY(1px);

    transform: translateX(20px) rotate(0deg) translateY(1px);
  }

  100% {
    -webkit-transform: translateX(20px) rotate(0deg);

    transform: translateX(20px) rotate(0deg);
  }
}

.trans-type-sec ul li a.addtocart-btn span {
  display: block;
  font-size: 6px;
  margin-top: 5px;
}
.trans-type-sec ul li a.addtocart-btn:hover {
  text-decoration: none;
}
/*.tabs ul li label img, .trans-type-sec ul li a img{filter: grayscale(1); opacity: 0.5; margin-right: 10px;}*/
.tabs ul li label br,
.trans-type-sec ul li a br {
  display: none;
}
.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #666;
}
/*
  .tabs ul li label:hover img, .tabs ul li label:focus img, .tabs ul li label:active img,
  .trans-type-sec ul li a:hover img, .trans-type-sec ul li a:focus img, .trans-type-sec ul li a:active img{ opacity: 0.9;}
  */
.tabs .slider,
.trans-type-sec .slider {
  position: relative;
  width: 50%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
}
.tabs .slider .indicator,
.trans-type-sec .slider .indicator {
  position: absolute;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  height: 4px;
  background: #ffc107;
  border-radius: 1px;
}
/*.tabs .content {margin-top: 10px;}*/
.tabs .content .quickform-sec {
  display: block;
  -webkit-animation-name: content;
  animation-name: content;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1.4;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  min-height: 290px;
}
.tabs .content .quickform-sec .txtbox {
  height: 34px;
}
.tabs .content .quickform-sec .siptoday-chk {
  /* right: 0; */
}
.tabs .content .quickform-sec .input-increment .input-group .btn {
  font-size: 13px;
}
.tabs .content .quickform-sec .sy_Celender {
  position: absolute;
  z-index: 0;
  right: 0px;
  top: auto;
  bottom: 10px;
}
.fundaddedtocart.fundDetail.openinvest
  .fundsec-fundinfo:hover
  .quickform-sec.active:before {
  background-color: rgba(0, 0, 0, 0.6);
  background-image: url(/src/assets/images/category/curved-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 14% bottom 18%;
  background-size: 160px;
  border-radius: 0 0 10px 5px;
  content: "Click ‘Added to cart’ button to remove from cart";
  color: #fff;
  height: 102.5%;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: -10px;
  padding: 0 30px;
  z-index: 1;
  display: flex;
  position: absolute;
  text-align: center;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
}
.listview
  .fundaddedtocart.fundDetail.openinvest
  .fundsec-fundinfo:hover
  .quickform-sec.active:before {
  background-image: url(/src/assets/images/category/curved-arrow-right.svg);
  background-position: right 20% bottom 5%;
  background-size: auto 160px;
  top: 0px;
  border-radius: 0 10px 10px 0;
  height: 100%;
}
.minamt-text {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 11px;
  color: #79869a;
}
.tabs .content .quickform-sec input {
  margin-bottom: 0;
}
.tabs .content .quickform-sec input.txtbox ~ .focus-border {
  bottom: 0;
}
.tabs .content .quickform-sec .amt-sec {
  padding: 80px 0px 40px;
}
.tabs .content .quickform-sec.quicksipform-sec .amt-sec {
  padding: 80px 0px 0px;
}
.tabs .content .quickform-sec .input-container,
.sidebar-icon-only
  .main-panel
  .tabs
  .content
  .quickform-sec
  .quick-amt-sec
  .input-container {
  margin-right: 10px;
  padding: 0;
}
.main-panel .tabs .content .quickform-sec .quick-amt-sec .input-container {
  margin-right: 5px;
}
.tabs .content .quickform-sec .quick-amt-sec {
  margin-top: 15px;
}
.tabs .content .quickform-sec .quick-amt-sec .input-container .radio-tile,
.sidebar-icon-only
  .main-panel
  .tabs
  .content
  .quickform-sec
  .quick-amt-sec
  .input-container
  .radio-tile {
  padding: 2px 5px;
}
.main-panel
  .tabs
  .content
  .quickform-sec
  .quick-amt-sec
  .input-container
  .radio-tile {
  padding: 2px 3px;
}
.tabs .content .quickform-sec .amountintext {
  margin-top: 5px;
  margin-bottom: 10px;
  color: #323b6d;
  text-align: center;
  max-width: 100%;
}
.tabs .content .quickform-sec .biginput {
  font-size: 20px;
  max-width: 270px;
  vertical-align: middle;
}
.tabs .content .quickform-sec .input-lable {
  font-size: 11px;
  margin-bottom: 0px;
}
.siptoday-chk {
  /* position: absolute;
    top: 0px;
    right: -8px; */
}
.tabs .content .quickform-sec .siptoday-chk {
  /* right: 0; */
  background-color: #ededed;
  padding: 1px 5px 3px;
  line-height: normal;
}
.siptoday-note-sec {
  flex: 0 0 55%;
  width: 55%;
  max-width: 100%;
}
.sidebar-icon-only .siptoday-note-sec {
  flex: 0 0 60%;
  width: 60%;
  max-width: 100%;
}
.sipInfoHoverText {
  display: none;
  text-align: left;
  background-color: #333;
  padding: 10px 15px;
  width: 205px;
  position: absolute;
  border-radius: 3px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 35%);
  top: 20px;
  color: #fff;
  font-size: 11px;
  line-height: 1.4;
  z-index: 3;
  text-transform: none;
  margin-bottom: 0;
  /* right: 10px; */
}
.sipInfoHoverText:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #333;
  right: 49px;
  top: -12px;
}
.sipInfoHoverText:after {
  width: 100%;
  height: 40px;
  content: "";
  position: absolute;
  top: -40px;
  left: 0;
}
.SIP_info {
  position: relative;
}
.SIP_info:hover .sipInfoHoverText {
  display: block;
  transform-origin: 100% 0;
  animation: fadeIn 0.3s ease-in-out;
}

.sipInfosy:before {
  right: auto;
  left: 5px;
}

.siptoday-chk input {
  vertical-align: middle;
  display: inline;
  margin: 0px 2px 0px 0px;
  transform: scale(1);
  cursor: pointer;
}
.siptoday-chk label {
  font-family: 'Roboto';
    font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  color: #1a294e;
  display: inline;
  cursor: pointer;
  margin-left: 5px;
  vertical-align: middle;
  line-height: 13px;
}
.tabs .content .quickform-sec .col-md-6:nth-child(even),
.tabs .content .quickform-sec .sipinputs-sec .col-md-auto {
  padding: 0 10px;
}
.tabs .content .quickform-sec .col-md-6:nth-child(odd) {
  padding: 0 10px;
}
/* .postloginfund-card .addtocart-btn-sec{border-top: 1px solid #ccc;} */
.postloginfund-card .addtocart-btn-sec .form-group {
  position: relative;
}
.postloginfund-card .addtocart-btn-sec .btn-addtocart {
  background-color: #fff;
  color: #fb4d4d;
  border: 1px solid #fb4d4d;
  font-size: 13px;
  padding: 10px 15px;
  text-align: center;
}
.postloginfund-card .addtocart-btn-sec span {
  margin-right: 5px;
}
.postloginfund-card .addtocart-btn-sec .btn-addtocart:hover {
  background-color: #fb4d4d;
  color: #fff;
  border: 1px solid #fb4d4d;
}
.postloginfund-card .addtocart-btn-sec .btn-addtocart:hover svg path {
  fill: #fff;
}
.quickform-sec .cardnote-small {
  padding: 5px;
}
.quickform-sec .cardnote-small p {
  font-size: 9px;
}
.circle {
  width: 25px;
  height: 25px;
  background: #fff;
  border: 2px solid #444444;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -21px;
  cursor: pointer;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.4) 0 2px 5px;
  margin: 0px 0px;
  -moz-transition: ease-in-out 100ms;
  -o-transition: ease-in-out 100ms;
  -webkit-transition: ease-in-out 100ms;
  transition: ease-in-out 100ms;
}
.fromMiddle:before,
.fromMiddle:after {
  z-index: 9999;
  -moz-transition-delay: 150ms;
  -o-transition-delay: 150ms;
  -webkit-transition-delay: 150ms;
  transition-delay: 150ms;
  -moz-transition: ease-in-out 400ms;
  -o-transition: ease-in-out 400ms;
  -webkit-transition: ease-in-out 400ms;
  transition: ease-in-out 400ms;
}
.circle:before {
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -1px;
  width: 10px;
  height: 2px;
  background-color: #444444;
  content: "";
  position: absolute;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.circle:after {
  width: 10px;
  height: 2px;
  background-color: #444444;
  content: "";
  left: 50%;
  top: 50%;
  margin-left: -5px;
  margin-top: -1px;
  position: absolute;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.fromMiddle span {
  width: 25px;
  height: 25px;
  background-color: #444444;
  display: inline-block;
  position: absolute;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  left: -2px;
  top: -2px;
  z-index: -9999;
  -moz-transform: scale(0.3);
  -ms-transform: scale(0.3);
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0;
  -moz-transition: ease-in-out 300ms;
  -o-transition: ease-in-out 300ms;
  -webkit-transition: ease-in-out 300ms;
  transition: ease-in-out 300ms;
}

.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label {
  cursor: default;
  color: #27306b;
  background-color: #fff;
  font-family: 'Roboto';
    font-weight: 700;
}
.circle[data-animation="magnify"]:not(.magnify):hover {
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
.fromMiddle:hover:before,
.fromMiddle:hover:after {
  position: absolute;
  background-color: #fff;
}
.fromMiddle:hover span {
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label
  img {
  filter: grayscale(0);
  opacity: 1;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(1):checked
    ~ ul
    > li:nth-child(1)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(1):checked ~ .slider {
  transform: translateX(0%);
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ .content
  > .quickform-sec:nth-child(1) {
  display: block;
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label {
  cursor: default;
  color: #27306b;
  background-color: #fff;
  font-family: 'Roboto';
    font-weight: 700;
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label
  img {
  filter: grayscale(0);
  opacity: 1;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(2):checked
    ~ ul
    > li:nth-child(2)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(2):checked ~ .slider {
  transform: translateX(100%);
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ .content
  > .quickform-sec:nth-child(2),
.quickform-sec.quicksipform-sec.active,
.quickform-sec.quicklumpsumform-sec.active {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  flex-wrap: nowrap;
}
.quickform-sec.quicksipform-sec.active > .row,
.quickform-sec.quicklumpsumform-sec.active > .row {
  margin: auto -15px;
}
@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@media (max-width: 1024px) {
  .list-grid-sec {
    display: none;
  }
  .search-box-sec {
    right: 0px;
    top: 5px;
  }
}

@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }
  .tabs ul li label br {
    display: initial;
  }
  .tabs ul li label svg {
    height: 1.5em;
  }
  .list-grid-sec {
    display: none;
  }
}
@media (max-width: 600px) {
  .tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }
  .tabs ul li label span {
    display: none;
  }
  .tabs .slider {
    display: none;
  }
  .tabs .content {
    margin-top: 20px;
  }
  .tabs .content quickform-sec h2 {
    display: block;
  }
}
.input-lable {
  color: #79869a;
  font-size: 12px;
  font-family: 'Roboto';
    font-weight: 400;
  text-transform: uppercase;
  font-weight: 500;
}
.amountintext {
  font-family: 'Roboto';
    font-weight: 400;
  color: rgba(33, 37, 41, 0.2);
  margin-top: 20px;
  /* max-width: 385px; */
}

.formRe {
  position: relative;
  width: 100%;
}
.css-1laao21-a112yText {
  border: 0;
}
.curFilter .css-1uccc91-singleValue {
  margin-left: 17px;
}
.curFilter .txtSelect .css-yk16xz-control,
.curFilter .txtSelect .css-yk16xz-control:focus {
  border: 1px solid #bfc3ce;
  border-radius: 0.25rem;
}
.curFilter .css-g1d714-ValueContainer .css-b8ldur-Input {
  padding: 10px 15px;
}
/* .quickform-sec .formRe.form-group, .quickform-sec .form-group{margin-bottom: 10px;} */
.fundsec-fundinfo .quicklumpsumform-sec .amt-sec {
  text-align: center;
}
.amtinput-sec .formRe {
  max-width: 270px;
  margin: 0 auto;
}
.txtbox {
  width: 100%;
  height: 38px;
  border: 0px;
  box-shadow: none;
  border-bottom: 1px solid #bfc3ce;
  font-size: 14px;
  color: #323b6d;
  font-family: 'Roboto';
    font-weight: 400;
}

.txtbox:hover,
.txtbox:focus {
  outline: none;

  box-shadow: none;
}
.input-increment .input-group .btn {
  border: 0;
  border-bottom: 1px solid #bfc3ce;
  border-radius: 0;
}
.input-number {
  border: 0;
  border-bottom: 1px solid #bfc3ce;
}
.postloginfund-card .cardnote-small {
  padding: 5px;
}
.note-light {
  color: #383d41;
  background-color: #f5f6fa;
  border-color: #dddddd;
}
.note-sec {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}
.postloginfund-card .cardnote-small p {
  font-size: 9px;
}

.no_return {
  font-size: 10px;
  padding: 0px 15px 0px 50px;
  margin-bottom: 30px;
  /* margin-top: 20px; */
  /* margin-bottom: 5px; */
  color: #999999;
  font-family: 'Roboto';
    font-weight: 400;
}

/* end postlogin  */

.fund_desc_icon img {
  margin: 0 auto;
}
.tooltip-item img {
  display: inline !important;
  margin-left: 2px;
}
.listview .no_return {
  padding: 15px 0px 0px 0px;
  font-size: 9px;
  width: 90%;
}
.listview .returns-value {
  padding-top: 10px;
}
.slick-slide .tabs ul li label img {
  display: inline-block;
}
/* .returns-sec .fundplan-radio-tile-group .fundplan-input-container:nth-child(1n+5){
  display: none;
  } */
@media (min-width: 320px) and (max-width: 767px) {
  .list-grid-sec {
    display: none;
  }
  .nav-sec {
    /* padding-top: 0px; */
  }
}
/* Quick buy popup */
.yg_qbShopIcon .yg_qbheading {
  color: #fff;
  font-size: 12px;
  line-height: normal;
  text-transform: uppercase;
  font-family: 'Roboto';
    font-weight: 400;
}
.yg_qbShopIcon {
  position: fixed;
  right: 10px;
  bottom: 10px;
  margin: auto;
  padding: 15px;
  width: 160px;
  height: auto;
  text-align: center;
  background-color: #e44d26;
  background-image: linear-gradient(135deg, #f16529 10%, #e44d26 100%);
  border-radius: 0.25rem;
  /* box-shadow: 0px 0px 10px 0px rgba(78, 78, 78, 0.35); */
  box-shadow: 0 0px 20px 0px rgba(78, 78, 78, 0.35);
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all ease-in 0.3s;
  -moz-transition: all ease-in 0.3s;
  -ms-transition: all ease-in 0.3s;
  -o-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}
.checkout-open .yg_qbShopIcon {
  -webkit-transition: all ease-in 0.3s;
  -moz-transition: all ease-in 0.3s;
  -ms-transition: all ease-in 0.3s;
  -o-transition: all ease-in 0.3s;
  transition: all ease-in 0.3s;
}
.yg_qbShopIcon img {
  margin-top: 18px;
  width: 50px;
}
.yg_qbshoppingicon {
  position: relative;
}
.yg_cartCount {
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 14px;
  background-color: #25375b;
  color: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  right: 22px;
  top: 12px;
  padding-top: 2px;
  text-align: center;
  box-shadow: 0px 0px 6px 0px rgba(78, 78, 78, 0.35);
}
.yg_qbPopupWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.7);
  top: 0px;
  left: 0px;
  z-index: 99999;
  /* display: none; */
}
.yg_qbPopup {
  width: 550px;
  /* height: 400px; */
  background-color: #ffffff;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 3px;
}
.yg_qbPopupTitle {
  font-family: 'Roboto';
    font-weight: 500;
  font-size: 22px;
  color: #1a294e;
  margin-bottom: 0px;
  padding: 15px 0px 15px 20px;
  border-bottom: 1px solid #cccccc;
}
.yg_qbItemsWrap {
  max-height: 200px;
  overflow-y: auto;
  padding: 0px 20px;
  margin-bottom: 10px;
}
.yg_qbItemsWrap .table td {
  border: 0px;
}
.yg_qbFundname {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 14px;
  color: #21295a;
  text-transform: capitalize;
  margin-bottom: 10px;
  max-width: 200px;
}
.yg_qbInvestType {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 14px;
  color: #21295a;
  text-transform: capitalize;
}
.yg_qbFundType {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 10px;
  color: #21295a;
  background-color: #e8e9ee;
  padding: 7px 10px;
  text-transform: uppercase;
  border-radius: 3px;
}
.yg_qbAmount {
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 19px;
  color: #21295a;
}
.yg_qbRemoveItemBtn {
  border: 0px;
  background-color: transparent;
  margin-top: 4px;
}
.yg_qbRemoveItemBtn:focus {
  outline: 0px;
}
.yg_qbItemsWrap .table tr {
  border-bottom: 1px solid #ededed;
}
.yg_qbHoldingWrap {
  padding: 0px 20px;
  box-shadow: 0px -5px 5px -2px #ededed;
}
.yg_holdInputWrap {
  margin-top: 20px;
  position: relative;
}
.yg_holdInputWrap label {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 12px;
  color: #adafb6;
  text-transform: uppercase;
}
.yg_holdInputWrap select {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 15px;
  color: #212529;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #bfc3ce;
  background-color: transparent;
  outline: 0px;
  padding: 0px 10px 5px 30px;
  padding-left: 0px;
  text-indent: 25px;
  text-overflow: ellipsis;
}
.yg_holdInputWrap img {
  position: absolute;
  bottom: 7px;
  left: 5px;
}
.yg_holdInputWrap input {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 15px;
  color: #212529;
  width: 100%;
  height: 38px;
  border: 0;
  border-bottom: 1px solid #bfc3ce;
  background-color: transparent;
  outline: 0px;
  padding: 0px 10px 5px 0px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}
.yg_checkoutWrap {
  font-family: 'Roboto';
    font-weight: 700;
  font-size: 28px;
  color: #ffffff;
  background-color: #77b936;
  padding: 10px 10px 10px 20px;
  position: relative;
}
.yg_checkoutamt {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}
.yg_qbCancelBtn {
  position: absolute;
  right: 0px;
  top: 0px;
  border: 0px;
  background-color: #ffffff;
  height: 100%;
  border-radius: 2px;
  box-shadow: 0px -5px 5px -2px #ededed;
}
.yg_qbCancelBtn:focus {
  outline: 0px;
}
.yg_qbCancelBtn img {
  width: 75%;
}
/* .listview {
  padding-bottom: 20%;
} */
@media (min-width: 320px) and (max-width: 767px) {
  .yg_qbPopup {
    width: 95%;
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: auto;
  }
  .yg_qbItemsWrap {
    padding: 0px;
  }
  .yg_qbAmount {
    font-size: 15px;
  }
  .yg_qbFundname {
    width: 115px;
  }
  .yg_qbShopIcon {
    /* bottom: 5%; */
    width: 95%;
  }
  .yg_qbShopIcon .yg_qbheading {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
  }
  .yg_qbshoppingicon {
    position: relative;
    width: 30%;
    display: inline-block;
  }
  .yg_qbShopIcon img {
    margin-top: 0px;
  }
  .yg_cartCount {
    right: 10px;
    top: -10px;
  }
  .quickinvest-form.active .tabs .content {
    margin-top: 0;
  }
  .tabs .content .quickform-sec .siptoday-chk {
    width: auto;
    display: inline-block;
  }
  .siptoday-note-sec {
    flex: 0 0 50%;
    width: 50%;
    max-width: 100%;
  }
}
@media (min-width: 576px) and (max-width: 767px) and (orientation: landscape) {
  .yg_qbPopup {
    height: 90%;
    overflow-y: auto;
  }
}
/* Quick buy popup */

/* compare page */
.fixed_compare {
  width: 240px;
  /* height:373px; */
  border: 1px solid #fb4d4d;
  position: fixed;
  top: 30%;
  background: #fff;
  z-index: 9999;
  border-radius: 4px;
  transition: all 0.5s;
  padding-bottom: 20px;
  right: -100%;
}

.fixed_compare_active {
  right: 0%;
}

.fixed_compare h3 {
  background: #fb4d4d;
  color: #fff;
  font-size: 14px;
  margin: 0px;
  font-family: 'Roboto';
    font-weight: 400;
  padding: 15px 15px;
}

.fund_com {
  width: 100%;
  padding: 15px 15px;
}

.sele_unothe {
  border: 1px dashed #bfc3ce;
  color: #fb4d4d;
  display: block;
  text-align: center;
  border-radius: 4px;
  margin: 15% auto;
  font-family: 'Roboto';
    font-weight: 500;
  padding: 10px 15px;
}

.com_btn {
  display: block;
  margin: auto;
  text-align: center;
  width: 71%;
  padding: 9px 2px;
}

.fundname_com {
  font-size: 16px;
  font-family: 'Roboto';
    font-weight: 400;
  color: #1a294e;
  position: relative;
  display: inline-block;
  padding-left: 40px;
}

.cm_logo {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;

  display: inline-block;
  left: 0;
}

/* compare page */

.minus-icon-cust {
  position: absolute !important;
  left: -10px !important;
  border-bottom: 0px !important;
}
.plus-icon-cust {
  position: absolute !important;
  right: -10px !important;
  border-bottom: 0px !important;
}
.minus-icon-cust:focus,
.plus-icon-cust:focus {
  box-shadow: none !important;
}
.slick-prev {
  left: -40px !important;
}
.slick-next {
  right: -40px !important;
}
@media (max-width: 767px) {
}
@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
}

@media (min-width: 768px) and (max-width: 992px) {
  .no_return {
    margin-bottom: 15px;
    min-height: 44px;
  }
  .fund-plan {
    padding: 0px 10px;
  }
  .padd0_tablet {
    padding: 0px;
  }
}

@media screen and (min-width: 980px) and (max-width: 1024px) {
  .cd-tab-filter ul {
    width: 70%;
    overflow: auto;
    white-space: nowrap;
  }
  .fundDetail .fund_Name h4 {
    font-size: 18px;
  }
  .fundDetail .fund_Name h4 .fundname-mo {
    font-size: 12px;
  }
  .fundDetail .fund_type {
    font-size: 8px;
    padding: 5px 10px;
  }
  .fundDetail .chk-compare svg {
    height: 22px;
  }
  .fundDetail .fund-plan label,
  .fundDetail .fund-option label {
    line-height: normal;
    font-size: 11px;
  }
  .fundDetail .option-select {
    width: 100%;
  }
  .fundDetail .nav_price span.navdown {
    display: block;
  }
  .fundDetail .navdatelbl {
    font-size: 13px;
    padding: 10px 5px;
  }
  .fundDetail .nav_price {
    font-size: 18px;
    line-height: normal;
  }
  .fundDetail .returns-yearslblsec .col-auto.returnsiconsec {
    display: none;
  }
  .fundDetail .returns-innersec:before {
    content: none;
  }
  .fundDetail .returns-lbl {
    font-size: 10px;
  }
  .fundDetail .amout_text .tooltipn {
    /* display: block; */
  }
  .fundDetail .fund_desc_icon img {
    height: 15px;
  }
  .fundDetail .returns-innersec {
    min-height: 106px;
  }
  .fundDetail .no_return {
    padding: 0px 15px;
  }
  .fundDetail .quicksipform-sec .input-container .radio-tile-label {
    font-size: 10px;
  }
  .fundDetail .input-container .radio-tile-label .rupeesign {
    padding-right: 1px;
  }
  .fundDetail .amtinput-sec .minamt-text {
    display: block;
  }
  .fundDetail .trans-type-sec ul li:nth-child(2) {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .listview .fundDetail .amout_text .tooltipn {
    display: inline;
  }
  .listview .fundDetail .returns-innersec {
    min-height: 57px;
    max-width: 324px;
    min-width: 324px;
  }
  .listview .fundDetail .fund-selection {
    padding-top: 20px;
  }
  .listview .fundDetail .no_return {
    margin: auto 0 auto -15px;
  }
  .listview .trans-type-sec ul li:nth-child(2) {
    flex: 0 0 auto;
    max-width: 100%;
  }
  .listview .trans-type-sec {
    flex: 0 0 18%;
    max-width: 18%;
  }
  .listview .circle {
    right: 115px;
  }
  .listview .trans-type-sec ul li a.addtocart-btn .default-txt {
    padding-left: 5px;
  }
  .listview .fundDetail .fundsec-innercontainer {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .listview .returnslbl > label {
    padding-left: 0px;
  }
  .listview .addtocart-btn-sec .btn-fundknowmore,
  .listview .addtocart-btn-sec .btn-investnow {
    font-size: 11px;
    padding: 5px 0;
  }
  .listview .returns-valuelblsec .col {
    padding-left: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .returns-innersec:before {
    box-shadow: 0px 3px 10px #d3d3d3;
  }
  .yg_activeReturn .radio-tile {
    box-shadow: 0px 3px 5px #c0c0c0 !important;
  }
  .listview
    .fundaddedtocart.fundDetail.openinvest
    .fundsec-fundinfo:hover
    .quickform-sec.active::before {
    background-position: right -125px bottom 5%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cd-tab-filter ul {
    width: 50%;
    overflow: auto;
    white-space: nowrap;
  }
  .search-box-sec {
    right: 0px;
    top: 5px;
  }
  .list-grid-sec {
    top: 5px;
    right: 2px;
  }
  .curFilter {
    top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .cd-tab-filter-wrapper {
    box-shadow: none;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .search-box-sec {
    left: 0px;
    right: 0px;
    top: -40px;
  }
  .cd-tab-filter {
    width: auto;
    height: auto;
  }
  .search-box-innersec input {
    width: 100%;
  }
  .search-box-innersec input:focus {
    width: 100%;
  }
  .cd-filter {
    width: 100%;
    white-space: nowrap;
    overflow: auto;
  }
  .cd-tab-filter ul {
    position: static;
    padding-left: 0px;
  }
  .cd-tab-filter li {
    display: inline-block;
    /* padding: 0 20px; */
    width: auto;
    color: #9a9a9a;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }
  .cd-tab-filter a {
    display: inline-block;
    width: 120px;
  }
  .rbt-menu > .dropdown-item {
    width: 100%;
  }
  .cd-tab-filter a.selected {
    background: transparent;
    color: #1a294e;
    font-family: 'Roboto';
    font-weight: 700;
    box-shadow: inset 0 -2px 0 #ffc107;
  }
  .slick-prev {
    left: -10px !important;
    margin-top: -15px;
  }
  .slick-next {
    right: -10px !important;
  }
  .curFilter {
    top: 10px;
  }
  .sele_unothe {
    margin: 5px auto;
  }
  .fixed_compare {
    padding-bottom: 0px;
  }
}

.curFilter {
  position: relative;
  /* top: 12px; */
}
.txtSelect .css-1g6gooi input,
.txtSelect .css-b8ldur-Input input {
  margin-bottom: 0px;
}

.yg_checkoutWrap_Overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: none;
}

/* .sipinputs-sec .react-datepicker-popper {
    transform: translate3d(-135px, -160px, 0px) !important;
    z-index: 9999;
  }
  .sipinputs-sec .react-datepicker__triangle {
    left: 120px !important;
    display: none;
  } */
.sliderCus .slick-list {
  padding: 50px 0px 0px 0px;
}
