/*--terns & condition--*/
.tabs {
  /* width: 100%;
  margin: 50px auto 0 auto;
  font-weight: 500; */
}
.tabs ul {
  /* width: 100%;
  text-align: center; */
}
.tabs li {
  /* width: auto;
  padding: 10px 5px;
  list-style: none;
  border-right: none;
  display: inline-flex;
  margin: 0 20px;
  border-bottom: none;
  text-transform: uppercase; */
}
.tabs li a {
  /* color: #666;
  text-decoration: none;
  outline: none;
  border-bottom: 2px solid transparent;
  font-size: 11px; */
}
h1.hd {
  color: #1a294e;
  font-size: 30px;
  line-height: 35px;
  position: relative;
  margin: 35px auto 20px;
  display: inline-block;
  font-family: "Roboto";
  font-weight: 400;
  text-align: center;
}

.mheading {
  text-align: center;
}

.divider {
  width: 80px;
  height: 1px;
  background-color: #ccc;
  margin: 0px 0px 25px;
}
.relative {
  position: relative;
}
.full_imge {
  width: 100%;
}
/* .img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto;
} */
.bannerHeading {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  color: #fff;
  margin: auto;
}
.color_white {
  color: #fff !important;
}
.headingH1 {
  color: var(--primaryBlue);
  font-size: 30px;
  line-height: 1.2;
  position: relative;
  margin: 35px 0;
  display: inline-block;
  font-family: "Butler";
  font-weight: 400;
  color: #FAAD19;
}
.heading {
  font-size: 14px;
  color: #000;
  margin-bottom: 3px;
  font-family: "Roboto";
  font-weight: 400;
}

.mnbxtc p {
  font-family: "Roboto";
  font-weight: 400;
  color: #1a294e;
}

/*terms&condition*/
.accordian-ul {
  list-style: none;
  padding: 0;
  margin: 30px;
}
.accordian-ul .li-accor {
  border-bottom: 1px solid #eee;
  padding: 20px 0px;
}
.accordian-ul li a {
  width: 100%;
  color: #333;
  font-size: 12px;
  outline: none;
  text-decoration: none;
  line-height: 19px;
  font-weight: 600;
  text-transform: uppercase;
}
.plus_icon {
  background: url(/assets/images/tnc/Plus.png) no-repeat 0 0;
  width: 40px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}
.inneraccor-body {
  padding-left: 1em;
  overflow: hidden;
  display: none;
  list-style: none;
}
.inneraccor-body p {
  margin: 15px 0;
  font-family: "Roboto" !important;
  font-weight: 400;
  border-bottom: none;
  padding: 0 30px;
  font-size: 13px;
  color: #666;
  line-height: 19px;
}

.inneraccor-body p a {
  color: #2956bd;
  font-size: 13px !important;
  display: inline-block !important;
  width: auto !important;
  text-transform: lowercase;
}

.innerNav {
  width: auto;
  /* height: 70px; */
  overflow: auto;
  white-space: nowrap;
  position: relative;
  padding: 0;
  background: #fff;
  clear: both;
  -webkit-box-shadow: 4px 5px 20px rgb(0 0 0 / 30%);
  box-shadow: 4px 5px 20px rgb(0 0 0 / 30%);
  margin: -38px auto 0;
  border-radius: 4px;
}
.innerNav,
.innerNav li {
  display: inline-block;
}
.innerNav li a.active {
  text-decoration: none;
  color: var(--primaryBlue);
  border-color: var(--accentBlue);
}
.innerNav li a,
.innerNav li a:hover {
  font-size: 16px;
  text-align: center;
  padding: 20px 15px !important;
  text-decoration: none;
  color: #6a738a;
  transition: all 0.3s ease;
  display: block;
  border-bottom: 4px solid transparent;
  font-family: "Roboto";
  font-weight: 400;
}
#legal .main-body {
  padding: 0px 25px 40px;
  background-color: #fff;
  margin-top: 0px;
}
.toggle.open .plus_icon {
  background: url(/assets/images/tnc/minus.png) no-repeat 0 0;
}
.inneraccor-body p {
  margin: 15px 0;
  font-weight: 400;
  border-bottom: none;
  padding: 0 30px;
  font-size: 13px;
  color: #666;
  line-height: 19px;
}
/* h1.hd:after {
  width: 71px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
  background: url(/assets/images/tnc/yellowcolor.png) no-repeat right
    bottom;
} */

#termsc .main-body {
  padding: 0px 25px 40px;
  background-color: #fff;
  margin-top: 0px;
  margin-bottom: 30px;
}

/* .headingH1:after {
  width: 71px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -10px;
  background: url(/assets/images/tnc/yellowcolor.png) no-repeat right
    bottom;
} */

.tncUrl {
  text-decoration: none;
  color: #000 !important;
}

@media (max-width: 1024px) {
  .form_main_wrapper {
    max-width: 96%;
  }
}
@media (max-width: 980px) {
  .calenderinput {
    position: relative;
    margin-bottom: 25px;
  }
  .innerNav {
    width: 100%;
    margin-top: 0px;
    height: auto;
  }
}

@media (max-width: 768px) {
  /* .img-fluid, .img-thumbnail {
    max-width: 100%;
    height: 220px;
}      */
}
@media (max-width: 730px) {
  /* .img-fluid, .img-thumbnail {
    max-width: 100%;
    height: 160px;
}     */
  .headingH1 {
    margin: 0;
  }
}

@media (max-width: 360px) {
  .innerNav,
  .innerNav li {
    display: block;
  }
  h1.hd {
    font-size: 24px;
  }
  .accordian-ul {
    margin: 0px;
  }
  #termsc .main-body,
  #legal .main-body {
    padding: 0px 10px 40px;
    margin-top: 0;
  }
  .inneraccor-body p {
    padding: 0 15px;
  }
  .bannerHeading {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: #fff;
    margin: auto;
    margin-top: -20px;
    font-size: 20px;
  }
  /* .img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto;
    height: 150px;
} */
}
@media (max-width: 320px) {
  .btn-lg {
    font-size: 13px;
    max-width: 270px;
  }
  .add_new_bank_main .btn-lg {
    min-width: 226px;
    margin: 0 auto;
  }
  h1.hd {
    font-size: 20px;
  }
}
