.text-right {
  text-align: right !important;
}
.slider_dots .slick-prev {
  left: 20px;
}
.slider_dots .slick-next {
  right: 20px;
}
.slider_dots .slick-track {
  font-size: 0;
}
.quick_ullink {
  list-style-type: none;
  clear: both;
  margin-top: 40px;
  padding: 0px;
  text-align: center;
}

.quick_ullink li {
  display: inline-table;
  padding: 5px 10px;
  position: relative;
}

.quick_ullink li a,
.quick_ullink li a:hover {
  text-decoration: none;
}

.quickLINkTAG {
  width: 120px;
  height: 72px;
  position: relative;
  margin: 0 auto;
}

.Quesmark {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 10px;
  right: -10px;
  background: url("../assets/images/home/ques.png") no-repeat center center;
  box-sizing: border-box;
}

.quickLINkTAG p {
  font-size: 22px;
  font-family: 'Roboto';font-weight: 700;
  color: #4e557e;
  text-align: center;
  width: 90px;
  border-radius: 4px;
  line-height: 25px;
  border: 1px solid rgba(93, 100, 137, 0.3);
}

.quick_ullink li .quikLink {
  width: 170px;
  border-radius: 15px;
  display: block;
  padding: 20px 10px;
  height: 145px;
  font-size: 14px;
  color: #211f20;
  text-align: center;
  font-family: 'Roboto';
    font-weight: 500;
  text-transform: uppercase;
  /* border: 1px solid #707070; */
  background: #fff;
  box-shadow: 0 14px 26px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-out;
}
.quick_ullink li a:hover .quikLink {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
    0 24px 46px rgba(220, 233, 255, 0.48);
}
.quikLinkname {
  z-index: 1;
  position: relative;
}
/* .quikLink .quick_icon {
    width: 40px;
    height: 40px;
    position: relative;
    overflow: hidden;
    margin: 0px auto;
    text-align: center;
    border: 1px solid red;
    margin-top: 20px;
    margin-bottom: 10px;
  } */
.quikLink .quick_icon:after {
  content: "";
  width: 52px;
  height: 52px;
  display: block;
  position: absolute;
  background: #dce9ff;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: opacity 0.3s ease-out;
}

.quikLink .quick_icon {
  width: 60px;
  height: 60px;
  position: relative;
  overflow: hidden;
  margin: 0px auto;
  text-align: center;
  /* border: 1px solid red; */
  margin-top: 10px;
  margin-bottom: 10px;
  /* width: 131px; */
  /* height: 131px; */
  border-radius: 50%;
  /* background: #fff; */
  border: 2px solid #dce9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}
.quick_icon img {
  height: 30px;
  z-index: 1;
}
/* .quick_ullink li:first-child a .quikLink-overlay{display: none;} */
.quikLink-overlay {
  width: 19px;
  position: absolute;
  height: 19px;
  border-radius: 50%;
  background: #dce9ff;
  top: 50px;
  left: 75px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}
.quick_ullink li a:hover .quikLink-overlay {
  transform: scale(12) translateZ(0);
}
.quick_ullink li a:hover .quick_icon {
  border: 2px solid #fff;
}
.quick_ullink li a:hover .quick_icon:after {
  background: #f1f7ff;
}
.innerNav.category-tabs {
  width: auto;
  /* height: 57px; */
  overflow: auto;
  white-space: nowrap;
  position: relative;
  padding: 0;
  background: #fff;
  clear: both;
  box-shadow: 0px 2px 10px #ccc;
  margin: 0 auto;
  border-radius: 4px;
}
.innerNav.category-tabs,
.innerNav.category-tabs li {
  display: inline-block;
}
.innerNav.category-tabs li a,
.innerNav.category-tabs li a:hover {
  font-size: 16px;
  text-align: center;
  padding: 15px 20px !important;
  text-decoration: none;
  color: #6a738a;
  transition: all 0.3s ease;
  display: block;
  border-bottom: 4px solid transparent;
  font-family: 'Roboto';
    font-weight: 400;
}
.innerNav.category-tabs li a.active {
  text-decoration: none;
  color: #1a294e;
  border-color: #ffc107;
}
.home-title-2 {
  color: #21295a;
  font-size: 30px;
  font-family: 'Roboto';
    font-weight: 700;
  line-height: normal;
  position: relative;
  display: inline-block;
}
.home-title-2:after {
  width: 71px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -5px;
  background: url("../assets/images/about/headingImage.png") no-repeat;
}
.title-red:after {
  width: 71px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  right: 0;
  bottom: -15px;
  background: url("../assets/images/home/headingImage-red.png") no-repeat;
}
.home-title-3 {
  color: #414979;
  font-size: 15px;
  font-family: 'Roboto';
    font-weight: 500;
  line-height: normal;
}
.home-para {
  color: #6a738a;
  font-size: 15px;
}
.leftright-img-sec {
  background: url("../assets/images/home/circle-bg.png") center center;
  text-align: center;
}
.leftright-img-sec img {
  -webkit-animation-name: float-img-x;
  animation-name: float-img-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.cashflowhome-sec .leftright-img-sec img {
  -webkit-animation-name: float-img-y;
  animation-name: float-img-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.read-more-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}
.read-more-btn {
  width: auto;
  height: auto;
}
.read-more-btn .read-more-circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: inline-block;
  margin: 0;
  /* width: 3rem;
    height: 3rem;
    background: #282936;
    border-radius: 1.625rem; */
}
.read-more-btn .read-more-circle .read-more-icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: -3px;
  bottom: 0;
  margin: auto;
  background: #fff;
}
.read-more-btn .read-more-circle .read-more-icon.read-more-arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}
.read-more-btn .read-more-circle .read-more-icon.read-more-arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fb4d4d;
  border-right: 0.125rem solid #fb4d4d;
  transform: rotate(45deg);
}
.read-more-btn .read-more-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: inline-block;
  /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
  padding: 0.75rem 0;
  margin: 0 0 0 0;
  color: #fb4d4d;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
}
/* .read-more-btn:hover .read-more-circle {
    width: 100%;
  } */
.read-more-btn:hover .read-more-circle .read-more-icon.read-more-arrow {
  background: #fb4d4d;
  transform: translate(1rem, 0);
}
/* .read-more-btn:hover .read-more-text {
    color: #fff;
  } */
.animate-float-img-x {
  -webkit-animation-name: float-img-x;
  animation-name: float-img-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.animate-float-img-y {
  -webkit-animation-name: float-img-y;
  animation-name: float-img-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes float-img-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@keyframes float-img-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
}

@-webkit-keyframes float-img-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes float-img-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.section_height {
  width: 100%;
  height: 300px;
  border: 1px solid red;
  color: red;
  margin: 10px auto;
  padding: 30px 0px;
  text-align: center;
}

.full-investmentbg {
  background: url("../assets/images/home/investment-philosophy.jpg") no-repeat
    center;
  background-size: cover;
  width: 100%;
  min-height: 245px;
  height: auto;
  position: relative;
  padding: 40px 0;
}

.small-heading {
  font-size: 24px;
  margin: 0;
  color: #ffc107;
  font-family: 'Roboto';
    font-weight: 400;
}

.big-description {
  font-size: 44px;
  line-height: 60px;
  /* color: #ffffff; */
  font-weight: bold;
  font-family: 'Roboto';font-weight: 700;
}

.dark-greytxt {
  color: #6a738a !important;
  text-decoration: none;
  font-family: 'Roboto';
    font-weight: 400;
}
.dark-greytxt:hover {
  text-decoration: none;
}

.mb-25 {
  margin-bottom: 25px;
}

.big-letter {
  color: #faa528;
  font-size: 104px;
  font-weight: bold;
  margin: 0;
  font-family: 'Roboto';font-weight: 700;
}

.small-meaning {
  font-size: 24px;
  color: #1a294e;
  margin: 10px 0;
  font-family: 'Roboto';
    font-weight: 400;
}

.red-txt {
  color: #fb4d4d;
}

.pt-80 {
  padding-top: 80px;
}

.padd-15 {
  padding: 10px 5px 10px 15px;
}

.border-box {
  border: 1px solid #e7e6ff;
  border-radius: 5px;
  margin-bottom: 30px;
}

.fixed-box {
  position: relative;
  min-height: 71px;
  background-color: rgba(255, 222, 222, 0.4);
  margin-right: 0;
  width: 67px;
  border-radius: 4px;
}

.logo-desc {
  font-size: 14px;
  color: #6a738a;
  line-height: 19px;
  font-family: 'Roboto';
    font-weight: 400;
}

.fixed-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.green-box {
  background-color: rgba(170, 252, 211, 0.4);
}

.key-Gradient {
  min-height: 245px;
  height: auto;
  background-image: linear-gradient(to right, #21295a, #4d568b);
  z-index: 1;
  margin: 0 0 5px;
  padding: 40px 0px;
}

.key-title {
  font-size: 33px;
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: 'Roboto';font-weight: 700;
}

/* blog  */
.key_left {
  width: 100%;
  padding-bottom: 20px;
}

.left-div {
  position: relative;
}

.main-blog {
  width: 100%;
  height: 431px;
  background: url("../assets/images/home/Main-blog.png") no-repeat center center;
  background-size: cover;
  background-position: top;
  position: relative;
  margin-bottom: 20px;
  border-radius: 5px;
}

.main-bloginfo {
  background-color: #ffffff;
  border-radius: 0 5px 5px 5px;
  padding: 20px 35px;
  position: absolute;
  bottom: -18%;
}

.divider-left {
  display: inline-block;
  width: 0;
  height: 4em;
  border-left: 2px solid #ffc107;
  border-right: 2px solid #ffc107;
  background: #ffc107;
  position: absolute;
  top: 8px;
  left: -35px;
}

.main-heading {
  font-size: 22px;
  color: #1a294e;
  margin: 0 0 5px 0;
  line-height: 32px;
  font-weight: bold;
  width: 65%;
  display: inline-block;
  font-family: 'Roboto';font-weight: 700;
}

.blog-list {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style-type: none;
}

.blog-list li {
  float: left;
  color: #999999;
  list-style-type: disc;
  font-size: 14px;
}

.blog-list li:nth-child(1) {
  list-style-type: none;
}

.direRight {
  width: 13px;
  height: 10px;
  display: block;
  background: url(../assets/images/rightarrow.svg) no-repeat;
  background-size: contain;
  position: absolute;
  z-index: 999;
  top: 48%;
  transition: all 0.3s ease;
  right: 4%;
  background-position: 100% 100%;
}

.blog-arrow {
  width: 25px;
  height: 25px;
  top: 40%;
  right: 7%;
}

.colKey_right {
  width: 100%;
  height: 110px;
  position: relative;
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  background: #fff;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.key1_img {
  height: 110px;
  /* background: url('../images/key1.jpg') no-repeat center; */
}

.key1_img img {
  width: 100%;
}

.key_05 {
  font-size: 16px;
  color: #1a294e;
  font-family: 'Roboto';
    font-weight: 400;
  margin: 0px;
  line-height: 19px;
}

.key1_img .key_imgPlay {
  width: 25px;
  height: 25px;
}

.paddlR {
  padding: 6% 6%;
  position: relative;
  box-sizing: border-box;
}

.colKey_right:hover .direRight {
  right: 2%;
}
.colKey_right:hover a {
  text-decoration: none;
}
.bottom-line {
  margin-bottom: 0;
  border: transparent;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
  border-radius: 0;
  height: auto;
  clear: both;
  padding: 20px 15px;
}

.blog-number {
  position: absolute;
  top: 0;
  left: 0;
  color: #272e59;
  background-color: #ffffff;
  border-radius: 0 0 5px 0;
  z-index: 1;
  margin: 0;
  padding: 2px 7px;
  font-size: 12px;
  font-weight: bold;
}

.blog-title {
  line-height: 21px;
  padding: 5px 0;
}

.blog-list li:nth-child(2) {
  margin-left: 30px;
}

/* download */
.space-top {
  margin-top: 40px;
  margin-bottom: 40px;
}

.multiple-girl img {
  margin-left: 25px;
  z-index: 2;
  position: absolute;
}

.multiple-div {
  min-height: 160px;
  height: auto;
  margin: 30px 0;
  background-image: linear-gradient(to right, #e1f1fe, #dceaf5, #f4f9ca);
  z-index: 1;
  padding: 35px 75px;
}

.device-title {
  font-size: 33px;
  line-height: 35px;
  font-weight: bold;
  color: #21295a;
  margin: 0 0 25px;
  font-family: 'Roboto';font-weight: 700;
}

.device-list {
  padding: 0;
  margin: 0;
  font-family: 'Roboto';
    font-weight: 500;
}

.device-list li {
  list-style-type: none;
  display: inline-block;
  font-size: 20px;
  color: #6a738a;
  vertical-align: middle;
  margin-right: 20px;
}
.sliderImg {
  /* width: 100%; */
  margin: 0px auto;
  max-width: 100%;
}
.oneLineText {
  font-family: 'Roboto';
    font-weight: 400;
  font-size: 16px;
  color: #21295a;
}
.mb15 {
  margin-bottom: 15px;
}
.pb80 {
  padding-bottom: 80px;
}
.m-tb10 {
  margin: 10px 0px !important;
}

/* css_yg */
@media (min-width: 768px) and (max-width: 992px) {
  .quick_ullink {
    overflow: auto;
    white-space: nowrap;
    min-height: 180px;
  }
  .quick_ullink li:first-child {
    margin-right: 10px;
  }
  .quikLink .quick_icon {
    width: 25px;
    height: 25px;
  }
  .quickLINkTAG {
    width: auto;
    height: auto;
    left: -10px;
  }
  .quickLINkTAG p {
    width: 70px;
    white-space: normal;
    font-size: 18px;
    line-height: 22px;
  }
  .Quesmark {
    width: 25px;
    height: 25px;
    right: -27px;
    background-size: contain;
  }
  .big-letter {
    font-size: 70px;
  }
  .small-meaning {
    font-size: 18px;
  }
  .blog-title {
    padding: 5px 0px 5px 5px;
  }
  .blog-list {
    padding: 0 0 0 5px;
  }
  .big-description {
    font-size: 24px;
    line-height: 40px;
  }
  .fixed-box {
    width: auto;
  }
  .logo-desc {
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .body_loader {
    display: none;
  }
  .quick_ullink {
    overflow: auto;
    white-space: nowrap;
    min-height: 130px;
  }
  .quick_ullink li {
    width: 47%;
    display: inline-block;
    white-space: normal;
    vertical-align: middle;
  }
  .quick_ullink li:first-child {
    white-space: normal;
    /* vertical-align: top; */
  }
  .quick_ullink li .quikLink {
    width: 100%;
    height: auto;
    padding: 12px 10px;
    font-size: 11px;
    border-radius: 10px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }
  .quick_ullink li a:hover .quikLink {
    transform: translateY(-5px) scale(1.005) translateZ(0);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.11),
      0 5px 20px rgba(220, 233, 255, 0.48);
  }

  .quikLink .quick_icon {
    /* width: 25px;
      height: 25px; */
    margin-top: 0px;
  }
  .quikLink-overlay {
    top: 32px;
    left: 55px;
  }

  .quickLINkTAG {
    width: auto;
    height: auto;
    top: 20px;
  }

  .Quesmark {
    width: 25px;
    height: 25px;
    background-size: contain;
    right: -5px;
  }

  .quickLINkTAG p {
    width: 70px;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 22px;
  }

  .big-description {
    line-height: normal;
    font-size: 16px;
  }

  .full-investmentbg {
    min-height: 160px;
  }

  .secPadd50 {
    padding: 30px 0px;
  }

  .big-letter {
    font-size: 40px;
  }

  .small-meaning {
    font-size: 12px;
  }

  .bg_color_full {
    background: #ffffff;
  }

  .key-title {
    padding-left: 15px;
  }

  .sabHeading {
    padding-left: 15px;
  }

  .invest_btn {
    margin-left: 15px;
  }

  .multiple-girl {
    display: none;
  }

  .space-top {
    margin: 0;
  }

  .multiple-div {
    margin: 0;
  }

  .device-list li {
    margin-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .quick_ullink li .quikLink {
    width: 120px;
    height: 100px;
    padding: 5px 10px;
  }
}
