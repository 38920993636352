.banner-placeholder{
    aspect-ratio: 720 / 540 ;
    --scrollwidth: calc(100vw - 100%);
    width: calc(100vw - var(--scrollwidth));
}
.banner-placeholder .desk-ban{
    display: none;
}
.banner-placeholder .mob-ban{
    display: block;
}

.mobi .mob-ban,
.deski .desk-ban{
    display: block;
}
.mobi .desk-ban,
.deski .mob-ban{
    display: none;
}


/* laptop */
@media only screen and (min-width: 992px) {
    .banner-placeholder{
        aspect-ratio: 1920 / 440 ;
        --scrollwidth: calc(100vw - 100%);
        width: calc(100vw - var(--scrollwidth));
    }
    .banner-placeholder .desk-ban{
        display: block;
    }
    .banner-placeholder .mob-ban{
        display: none;
    }
}