.sujeet {
  color: red;
}
.aboutCounter {
  margin: 30px auto 0;
  padding: 0px;
  display: block;
  max-width: 95%;
  text-align: center;
}

.aboutCounter li {
  display: inline-table;
  text-align: center;
  width: 25%;
  padding: 10px 15px;
}

.aboutCounter p {
  font-family: 'Roboto';font-weight: 400;
  font-size: 50px;
  margin: 0px;
  color: #ffc107;
}

.aboutCounter span {
  font-family: 'Roboto';font-weight: 400;
  font-size: 16px;
  color: #6a738a;
}

.buildImg {
  width: 100%;
  min-height: 460px;
  position: relative;
  background: url("../assets/images/about/building.jpg") no-repeat;
  background-position: bottom;
  border-bottom: 30px solid #c3c7d0;
}

.aboutbig {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  width: 47%;
  bottom: -18px;
  z-index: 99;
}

.bluetrust {
  width: 100%;
  background: #1a294e;
  min-height: 445px;
  padding: 10px 20px;
  padding-top: 20px;
}

.trustImg {
  position: absolute;
  width: 86%;

  left: 8%;
  margin-top: 13%;
}

.height-70 {
  height: 70px;
}

.twoCpara {
  font-family: 'Roboto';font-weight: 400;
  color: #1a294e;
  font-size: 20px;
  margin: 30px 0px;
}

.twoCpara span {
  color: #fb4d4d;
}

.blueAbt {
  background: #1a294e;
  min-height: 490px;
  padding: 50px 50px 20px 50px;
}
.blueAbt p{
  font-size: 16px;
  line-height: 25px;
  text-align: left;
  color: #FFFFFF;
  margin-bottom: 30px;
  font-family: 'Roboto';font-weight: 400;
}

.dividerdotted {
  height: 1px;
  margin-bottom: 30px;
  border-bottom: 1px dotted #c3c7d0;
}

.bginvet2 {
  background: url("../assets/images//about/investment2.png") no-repeat center
    center;
  background-size: 100% 100%;
  min-height: 490px;
}

.fourTRingle {
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;

  flex-direction: row;
}

.fourTRingle li {
  width: 25%;
  min-height: 10px;
  display: inline-block;
  padding: 1% 4.5%;
  box-sizing: border-box;
  text-align: center;
}

.fourTRingle li p {
  font-family: 'Roboto';font-weight: 500;
  font-size: 17px;
  line-height: 18px;
  color: #1a294e;
  word-break: break-word;
}

.capNameLetter {
  width: 100%;
  display: block;
  transition: all 0.3s ease;
  height: 178px;
  border: 1px solid #1a294e;
  margin: 0px auto;
  font-family: 'Roboto';font-weight: 700;
  font-size: 80px;
  color: #1a294e;
  text-align: center;
  vertical-align: middle;
  padding-top: 18px;
  display: block;
  margin-bottom: 30px;
  position: relative;
}

.capNameLetter:after {
  content: "";
  transition: all 0.3s ease;
  display: block;
  position: absolute;
  bottom: -16px;
  right: 0;
  left: 0;
  margin: auto;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-right: 1px solid #1a294e;
  border-bottom: 1px solid #1a294e;
  -moz-transform: rotate(-313deg);
  -webkit-transform: rotate(-313deg);
}

.capNameLetter:hover {
  background: #fb4d4d;
  color: #fff;
  border-color: transparent;
}

.capNameLetter:hover::after {
  background: #fb4d4d;
  border-color: transparent;
}

.bannersitfull {
  width: 100%;

  background: #1a294e url("../assets/images/about/star.svg") no-repeat center
    center;
  background-size: cover;
  padding-bottom: 90px;
}

.doubleBtn {
  background: url("../assets/images/about/focusbtn.svg") no-repeat;
  width: 240px;
  height: 75px;
  background-size: 100% 100%;
  display: inline-block;
  font-family: 'Roboto';font-weight: 700;
  font-size: 21px;
  text-align: center;
  vertical-align: middle;
  padding-top: 17px;
  margin-bottom: 22px;
  color: #1a294e;
}

.bordleft {
  border-left: 1px dashed #c3c7d0;
}

.paddCenter {
  padding: 0px 39px;
}

.yellowStric {
  background: #ffc107;
  min-height: 100px;
  position: relative;
}

.downlaodbtn {
  display: inline-block;
  margin-top: 10px;
}

.pdffile img {
  position: absolute;
  top: -33px;
  max-width: 90%;
}
